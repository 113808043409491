import { initGlobalState, MicroAppStateActions } from 'qiankun'
// 公共数据存放
const initState = {
  // appLoading: true,
  appRouter: '',
  isCollapse: false,
}
const actions = initGlobalState(initState)

export default actions
