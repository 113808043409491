import { GET_TOKEN_KEY, GET_USER_INFOS_KEY } from 'comm/src/config' // GET_USER_INFOS_KEY
// import commStore from '../store'
import actions from './actions'

const subMenus = JSON.parse(localStorage.getItem('menus')) // commStore.getters[`${GET_USER_INFOS_KEY}/menus`] // 所有子菜单，用于显示在左边的
const mainMenus = JSON.parse(localStorage.getItem('classify')) // commStore.getters[`${GET_USER_INFOS_KEY}/mainMenu`] // 所有应用，显示在头部的
// 子应用路由权限控制
function subRouterPermission(router, store) {
  let hadLoad = false // 是否已经拉取过菜单，菜单只拉一次就好
  router.beforeEach((to, from, next) => {
    const token = sessionStorage.getItem(GET_TOKEN_KEY)
    if (token && !hadLoad) {
      hadLoad = true
      store.dispatch('permission/FETCH_PERMISSION').then(() => {
        // next({ path: to.path })
        next({ ...to })
      })
    } else {
      next()
    }
  })
  router.afterEach((to) => {
    // console.log('toto', to)
    if (to.meta.isHidden && to.meta.isAddToTab !== true) return

    to.meta.appMainRouter = sessionStorage.getItem('currentApp')
    const currentRouterInfo = {}
    // currentRouterInfo.fullPath = to.fullPath
    currentRouterInfo.path = to.path
    currentRouterInfo.fullPath = `${to.meta.appMainRouter}#${currentRouterInfo.path}`
    currentRouterInfo.meta = Object.assign(
      {
        appMainRouter: sessionStorage.getItem('currentApp'),
        name: to.params.tabName || to.params.equipmentSn || to.meta.name,
      },
      to.meta ?? {}
    )
    currentRouterInfo.meta.matched = to.matched.map((item) => item.path)
    actions.setGlobalState({ appRouter: JSON.stringify(currentRouterInfo) })
    store.commit('permission/SET_CURRENT_MENU', to.path)
    // 保存当前菜单到tab栏
    store.commit('SAVE_TAB_MENUS', currentRouterInfo)
  })
}
// 获取授权的路由
export const getSubPermissionMenus = (currentApp) => {
  const currentAppInfo = getItemInList(currentApp)
  const permissionRoutes = subMenus.filter(
    (sItem) => sItem.parentId === currentAppInfo[0].id
  )
  return permissionRoutes
}

// 通过path过滤出当前子应用的详细数据
export const getItemInList = (currentApp) => {
  const allApps = bearArr(mainMenus, 'children')
  const currentAppInfo = allApps.filter(
    (item) => item.path === currentApp.split('/')[1]
  )
  return currentAppInfo
}
/**
 * 拍平数组
 * list 数组
 * key 需要拍平哪个字段的数据
 * */
export const bearArr = (list, key, newArr = []) => {
  list.forEach((item) => {
    if (item[key] && Array.isArray(item[key])) {
      bearArr(item[key], key, newArr)
    }
    newArr.push(item)
  })
  return newArr
}

export default subRouterPermission
