// ele配置
import {
  Autocomplete,
  Avatar,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Backtop,
  Checkbox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Icon,
  Input,
  Loading,
  Menu,
  MenuItem,
  Submenu,
  Select,
  Option,
  Tooltip,
  Tag,
  Tree,
  MessageBox,
  Notification,
  Message,
  Form,
  FormItem,
  Carousel,
  CarouselItem,
  Tabs,
  TabPane,
  Container,
  Header,
  Row,
  Col,
  Popover,
  Badge,
  Dialog,
  Upload,
  CheckboxGroup,
  Table,
  TableColumn
} from 'element-ui'

export default {
  components: [
    Autocomplete,
    Avatar,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Backtop,
    Checkbox,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Icon,
    Input,
    Loading,
    Menu,
    MenuItem,
    Submenu,
    Select,
    Option,
    Tooltip,
    Tag,
    Tree,
    Form,
    FormItem,
    Carousel,
    CarouselItem,
    Tabs,
    TabPane,
    Container,
    Header,
    Row,
    Col,
    Popover,
    Badge,
    Dialog,
    Upload,
    CheckboxGroup,
    Table,
    TableColumn
  ],
  /**
   *
   * @param {*} Vue
   */
  install(Vue) {
    // Vue.prototype.$ELEMENT = { size: 'small', zIndex: 10 }
    Vue.prototype.$alert = MessageBox.alert
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$prompt = MessageBox.prompt
    Vue.prototype.$notify = Notification
    Vue.prototype.$message = Message
  }
}
