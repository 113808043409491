/**
 * axios二次配置
 */
import axios from 'axios'
import { Message } from 'element-ui'
import requestError from './request-error'
// import store from '../store'
// import { TOKEN } from './getUserInfo' // 静态变量，一直为空
import { GET_TOKEN_KEY, HEADER_TOKEN_KEY, RES_OK_CODE } from '../config'
// 域名地址
axios.defaults.baseURL = ''
export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URLPREFIX, // url的前缀,
})
//  REQUEST 请求异常拦截
axiosInstance.interceptors.request.use(
  (config) => {
    let tokenInfoString = sessionStorage.getItem(GET_TOKEN_KEY)
    let token = ''
    if (tokenInfoString) {
      const tokenInfo = JSON.parse(tokenInfoString)
      token = `${tokenInfo.token_type} ${tokenInfo.access_token}`
    }
    const dev = process.env.VUE_APP_DEV
    if (dev) config.headers.version = dev
    // 将Token添加到请求头里面
    token && (config.headers[HEADER_TOKEN_KEY] = token)
    console.log('config', config)
    return config
  },
  (err) => {
    // 错误处理
    Message.error('请求超时!')
    return Promise.reject(err)
  }
)

//  RESPONSE 响应异常拦截
axiosInstance.interceptors.response.use(
  (response) => {
    const { code, message, data } = response.data
    if (
      response.config.responseType === 'blob' ||
      response.config.responseType === 'arraybuffer'
    ) {
      //下载excel类型
      // return downloadFile(response)
    }
    if (typeof code === 'undefined') {
      return response
    }
    if (code !== 200) {
      Message.error(message)
      return
    } else {
      return {
        code,
        data,
        message,
      }
    }
    // console.log('response', response)
    // const { code, message, data } = response.data
    // if (code === 20001 || code === 50012 || code === 50014) {
    //   // linkToLogin()
    //   return
    // }
    // if (code !== RES_OK_CODE) {
    //   showMessageOnce(message || '服务器异常')
    //   return {
    //     code,
    //     message,
    //   }
    // } else {
    //   return {
    //     code: RES_OK_CODE,
    //     data,
    //     message,
    //   }
    // }
  },
  (err) => {
    if (err && err.response) {
      err.message = requestError(err.response.status)
      // const hasMessage = document.body.querySelector('.el-message')
      // console.log('hasMessage', hasMessage, !hasMessage)
      // if (!hasMessage) {
      //     Message({ message: err.message, type: 'error', duration: 1000 })
      // }
      showMessageOnce(err, 'error')
      // if (err.response.status === 401) {
      //   store.commit('userInfos/loginOut')
      //   setTimeout(() => {
      //     window.location.href = location.origin
      //   }, 2000)
      // }
    } else {
      err.message = '连接服务器失败!'
    }
    showMessageOnce(err)
    // Message.error({ message: err.message })
    return Promise.resolve(err)
  }
)

// 消息提示只出一个
function showMessageOnce(data, type = 'error', duration = 2000) {
  let hasMessage = document.body.querySelector('.el-message')
  if (data && !hasMessage) {
    Message({ message: data.message, type, duration })
  }
}

// 接口域名
// export const baseUrl = axios.defaults.baseURL;
const AJAX = (
  url,
  params,
  method,
  { headers = '', responseType = '', timeout = 60000, parameterType = 'data' }
) => {
  let config = {
    method: method,
    timeout: timeout,
    url: url,
    responseType: responseType,
  }
  if (method === 'get') {
    headers = Object.assign({}, headers)
    config['headers'] = headers
    config['params'] = params
  } else {
    if (parameterType === 'params') {
      headers = Object.assign({}, headers)
      config['headers'] = headers
      config['params'] = params
    } else {
      headers = Object.assign({}, headers)
      config['headers'] = headers
      config['data'] = params
    }
  }
  return axiosInstance(config)
}

export const http = {
  post: (url, params, config = {}) => {
    return AJAX(url, params, 'post', config)
  },
  get: (url, params, config = {}) => {
    return AJAX(url, params, 'get', config)
  },
  put: (url, params, config = {}) => {
    return AJAX(url, params, 'put', config)
  },
  delete: (url, params, config = {}) => {
    return AJAX(url, params, 'delete', config)
  },
}
// // POST 方法封装 (处理参数与文件上传)
//  const post = (url, params, config = {}) => {
// 	return AJAX(url, params, 'post', config);
// }

// //  GET 方法封装
//  const get = (url, params, config = {}) => {
// 	return AJAX(url, params, 'get', config);
// }

// //  PUT 方法封装
//  const put = (url, params, config = {}) => {
// 	return AJAX(url, params, 'put', config);
// }

// //  DELETE 方法封装
//  const deleteRequest = (url, params, config = {}) => {
// 	return AJAX(url, params, 'delete', config);
// }
