<template>
  <div class="layout" v-loading="isLoading">
    <layout-header
      class="layout-header"
      :current-menu="currentMenu"
      :navbar-menus="navbarMenus"
      @select="selectMenu"
      v-if="!hiddenTopBar"
    ></layout-header>
    <div class="main-content">
      <!-- 子应用盒子 -->
      <div id="root-view" class="app-view-box"></div>
    </div>
    <service-expires />
  </div>
</template>

<script>
import LayoutHeader from './LayoutHeader/index.vue'
import { NAVBAR_MENUS } from 'config' // 当前本地配置的子应用
import { mf } from 'utils'
// import store from 'comm/src/store'
import { treeFormat } from 'comm/src/utils/utils'
// import { GET_TOKEN_KEY, GET_USER_INFOS_KEY } from 'comm/src/config'
import { mapState } from 'vuex'
import setWebsiteIconAndTitle from 'comm/src/utils/normal/setWebsiteIconAndTitle'
import actions from 'utils/actions'
import ServiceExpires from '@/components/serviceExpires'
const _subAppContainer = '#root-view'

export default {
  data() {
    return {
      currentMenu: '',
      navbarMenus: [],
      mainMenu: JSON.parse(localStorage.getItem('classify')),
      menus: JSON.parse(localStorage.getItem('menus')),
      // editableTabsValue: ''
      tabMenusList: [],
      isCollapse: false,
      isLoading: false
    }
  },
  components: {
    LayoutHeader,
    ServiceExpires
  },
  computed: {
    ...mapState(['tabMenus', 'subRouterInfo']),
    // tabMenusList() {
    //   const currentApp = sessionStorage.getItem('currentApp')
    //   if (this.tabMenus && this.subRouterInfo) {
    //     this.tabMenus.forEach(item => {
    //       console.log('item',item)
    //       const subRouter = item.routerFullPath && item.routerFullPath.split('#')[1]
    //       if (subRouter === this.subRouterInfo.fullPath && this.subRouterInfo.meta.appMainRouter === currentApp) {
    //         item['menuName'] = this.subRouterInfo.meta.name
    //       }
    //     })
    //     return this.tabMenus
    //   }
    //   return []
    // },
    editableTabsValue() {
      return (
        this.subRouterInfo && `${this.subRouterInfo.meta.appMainRouter}#${this.subRouterInfo.path}`
      )
    },
    hiddenTopBar() {
      // 是否不显示头部模块，大屏和总览是全屏显示的，没有头部
      return this.$route.path === '/largeScreen' || this.$route.path === '/allOverview'
    }
  },
  created() {
    let sysConfig = JSON.parse(localStorage.getItem('pagedata'))
    sysConfig && setWebsiteIconAndTitle(sysConfig.websitIcon, sysConfig.platformName)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // 已经授权的子应用名称，取path字段
      this.getPermissionApps()
      this.setMenus()
      this.getGlobalState()
    },
    // 订阅子应用派发的状态
    getGlobalState() {
      actions.onGlobalStateChange(globalState => {
        this.isCollapse = globalState.isCollapse
        const appRouterInfo = JSON.parse(globalState.appRouter)
        this.$store.commit('SAVE_SUB_ROUTER_INFO', appRouterInfo)
        const currentRouterInfo = {}
        const currentAppRouter = appRouterInfo.meta.appMainRouter
        // 这里限制了子应用只能走hash模式，为了生成菜单的tab选项卡
        currentRouterInfo.routerFullPath = `${currentAppRouter}#${appRouterInfo.path}`
        currentRouterInfo.menuName = appRouterInfo.meta.name
        const routerInTabMenusList = this.tabMenusList.map(item => item.routerFullPath)
        this.currentMenu = currentAppRouter
        !routerInTabMenusList.includes(currentRouterInfo.routerFullPath) &&
          this.tabMenusList.push(currentRouterInfo)
      })
    },
    getPermissionApps() {
      // 此系统只取当前权限中的menuType 为top的菜单，不管菜单的分类先
      let allApps = [] // 所有授权的app
      let permissionApps = [] // 当前本地中在 allApps 里的app，只取本地有的app启动
      this.mainMenu.forEach(item => {
        if (item.children && item.children.length > 0) {
          allApps = [...allApps, ...item.children]
        }
      })
      // NAVBAR_MENUS.forEach(m => {
      //   allApps.forEach(p => {
      //     if (p.path === m.name) {
      //       m.menuName = p.menuName
      //       permissionApps.push(m)
      //     }
      //   })
      // })
      allApps.forEach(p => {
        NAVBAR_MENUS.forEach(m => {
          if (p.path === m.name) {
            m.menuName = p.menuName
            permissionApps.push(m)
          }
        })
      })
      console.log(permissionApps)
      this.navbarMenus = permissionApps
    },
    setMenus() {
      const apps = []
      this.navbarMenus.forEach(m => {
        m.container = _subAppContainer
        if (process.env.VUE_APP_BUILD_ENV === 'sgl' || process.env.VUE_APP_BUILD_ENV === 'prd') {
          m.loader = this.loader
        }
        // m.loader = this.loader
        const defaultAppConfig = mf.createDefaultAppConfig(this)
        apps.push(Object.assign(defaultAppConfig, m))
      })
      // 注册子应用并启动微服务
      mf.registerAndStart(apps)
      this.getCurrentMenu(apps[0])
    },
    // 格式化数组，转化为树结构
    formatList(list) {
      if (!Array.isArray(list)) throw new Error('list不是数组!')
      return treeFormat(list, 'id', 'parentId', 'children')
    },
    getCurrentMenu(current) {
      // 获取当前页面 菜单刷新后能自动选中
      let currentUrl = window.location.href
      let currentPage = currentUrl.split('/')[3].replace('#', '')
      const _defaultActive = current.activeRule
      this.currentMenu = currentPage ? '/' + currentPage : '/' + _defaultActive
    },
    /***
     * @param {Object} systemMenu 必传，格式化后的菜单对象，由layoutHeader中格式化菜单
     * @param {String} targetPath 选填，指定跳转链接，没有则使用菜单对象中的 activeRules 作为跳转链接地址
     */
    // 选中激活快捷访问系统平台，变更本地存储中的当前激活系统
    async selectMenu(systemMenu, targetPath) {
      const path = targetPath || `/${systemMenu.activeRule}`
      localStorage.setItem('currentSys', JSON.stringify(systemMenu))
      this.currentMenu = `/${systemMenu.activeRule}`
      if (systemMenu.name === 'largeScreen') {
        window.open(`/largeScreen`, '_blank')
        return
      }
      // 有拖拽，没直接使用el-menu，所以需要手动触发路由跳转
      this.$router.push(path)
    },
    // 废弃
    changeTab(val) {
      this.$router.push({ path: val.routerFullPath })
    },
    // 废弃
    closeTabs(menu) {
      const tabLength = this.tabMenusList.length
      if (tabLength > 1) {
        this.tabMenusList = this.tabMenusList.filter(
          item => item.routerFullPath !== menu.routerFullPath
        )
        this.tabMenusList.length && this.changeTab(this.tabMenusList[this.tabMenusList.length - 1])
      }
    },
    // 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
    loader(loading) {
      this.isLoading = loading
    }
  }
}
</script>

<style lang="scss">
@import './style/index.scss';
</style>
