<!-- 框架层搜索栏，原新余仅提供远传使用，基本逻辑样式照搬新余 -->
<template>
  <el-select
    v-model="unknownId"
    :remote-method="remoteMethod"
    :loading="loading"
    class="layout-search"
    filterable
    remote
    :clearable="true"
    reserve-keyword
    placeholder="设备编号、用户/监测点编号、用户/监测点名称、仪表出厂编号"
    popper-class="layout-search-popper"
  >
    <i slot="prefix" class="iconfont icon-sousuo"></i>
    <el-option
      v-for="item in deviceIdOptions"
      :key="item.id"
      :label="item.equipmentSn"
      :value="item.id"
      @click.native="unknownIdChange"
    >
      <template>
        <div class="option_list_body">
          <div class="option_list_class">
            <div class="option_item_class">
              <span class="itam_span"><img :src="returnIconByType(item.equipmentType)" /></span>
              <span
                ><b>{{ returnTitleByType(item.equipmentType, item.spec) }}</b></span
              >
            </div>
            <div class="option_item_class">
              <span>{{ returnCodeByType(item.equipmentType) }}：</span>
              <span>{{ item.equipmentSn }}</span>
            </div>
            <div class="option_item_class">
              <span>{{ returnUserCodeByType(item.equipmentType) }}：</span>
              <span>{{ item.enjoySn }}</span>
            </div>
            <div class="option_item_class">
              <span>{{ returnNameByType(item.equipmentType) }}：</span>
              <span>{{ item.enjoyName }}</span>
            </div>
          </div>
          <hr />
        </div>
      </template>
    </el-option>
  </el-select>
</template>

<script>
import { searchDevice } from 'comm/src/api/user.js'
import { pageGlobalUrl } from 'comm/src/api/common.js'
import fireHydrant from '@/assets/img/fire_hydrant.png'
import pressure from '@/assets/img/pressure.png'
import valve from '@/assets/img/valve.png'
import waterMeter from '@/assets/img/water_meter.png'
import waterQuality from '@/assets/img/water_quality.png'
import { setSessionStorageTabs } from 'comm/src/utils/utils'
// import commStore from 'comm/src/store'

export default {
  data() {
    return {
      unknownId: '',
      loading: false,
      deviceIdOptions: []
    }
  },
  methods: {
    returnNameByType(t) {
      switch (t) {
        case 'REMOTE_METER_LARGE':
          return '用户名称'
        case 'REMOTE_METER_SMALL':
          return '用户名称'
        case 'PRESSURE_MONITOR':
          return '监测点名称'
        case 'WATER_MONITOR':
          return '监测点名称'
        case 'REMOTE_VALVE':
          return '监测点名称'
        case 'FIRE_HYDRANT':
          return '监测点名称'
        default:
          return ''
      }
    },
    returnUserCodeByType(t) {
      switch (t) {
        case 'REMOTE_METER_LARGE':
          return '用户编号'
        case 'REMOTE_METER_SMALL':
          return '用户编号'
        case 'PRESSURE_MONITOR':
          return '监测点编号'
        case 'WATER_MONITOR':
          return '监测点编号'
        case 'REMOTE_VALVE':
          return '监测点编号'
        case 'FIRE_HYDRANT':
          return '监测点编号'
        default:
          return ''
      }
    },
    returnCodeByType(t) {
      switch (t) {
        case 'REMOTE_METER':
          return '水表编号'
        default:
          return '设备编号'
      }
    },
    returnTitleByType(t) {
      switch (t) {
        case 'REMOTE_METER_LARGE':
          return '远传大表'
        case 'REMOTE_METER_SMALL':
          return '远传小表'
        case 'PRESSURE_MONITOR':
          return '压力监测设备'
        case 'WATER_MONITOR':
          return '水质监测设备'
        case 'REMOTE_VALVE':
          return '远传阀门'
        case 'FIRE_HYDRANT':
          return '消防栓'
        default:
          return ''
      }
    },
    returnIconByType(t) {
      // 目前大表小表全部都是 REMOTE_METER 起始，当然没有该判断走到默认也是同样的结果
      if (t.startsWith('REMOTE_METER')) return waterMeter

      switch (t) {
        case 'PRESSURE_MONITOR':
          return pressure
        case 'WATER_MONITOR':
          return waterQuality
        case 'REMOTE_VALVE':
          return valve
        case 'FIRE_HYDRANT':
          return fireHydrant
        default:
          return waterMeter
      }
    },
    unknownIdChange() {
      let equipment = {
        id: '',
        menuName: '',
        menuType: 'left',
        path: 'dataDetail',
        platform: 'web'
      }
      let el = this.deviceIdOptions.find(o => o.id === this.unknownId)
      if (el) {
        equipment.params = {
          id: el.id,
          orgId: el.orgId,
          spec: el.spec,
          pointId: el.pointId,
          enjoyName: el.enjoyName,
          enjoySn: el.enjoySn,
          remoteMeterId: el.id,
          equipmentType: el.equipmentType,
          equipmentSn: el.equipmentSn,
          longitude: el.longitude,
          latitude: el.latitude,
          level: el.level
        }
        equipment.id = this.unknownId
        equipment.menuName = el.equipmentSn
        setSessionStorageTabs(equipment)
        this.$router.push(`${this.$route.path}#/dataDetail/${el.equipmentSn}`)
      }
    },
    async remoteMethod(keyWord) {
      keyWord = keyWord.trim() // 修复以前新余输入空格也会一直走查询
      if (!keyWord) {
        this.deviceIdOptions = []
        return
      }
      // if (keyWord.length <= 3) {
      //   this.deviceIdOptions = []
      //   return
      // }

      this.loading = true
      const { code, data } = await searchDevice({ keyWord, pageAble: false }, pageGlobalUrl)
      this.loading = false

      if (code === 200 && data) this.deviceIdOptions = data.records
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-search {
  margin-right: 10px;
  /deep/.el-icon-circle-close {
    line-height: 30px !important;
    margin-right: 26px;
  }

  /deep/ {
    .el-input__inner {
      width: 400px;
      height: 28px;
      line-height: 28px;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
      font-size: 12px !important;
      padding-left: 10px !important;
    }

    .el-input__prefix {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      width: calc(100% - 10px) !important;

      i {
        position: absolute;
        right: 5px;
      }
    }
  }
}
</style>

<style lang="scss">
.layout-search-popper {
  .el-select-dropdown__wrap {
    max-height: 77.8vh;
  }

  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #fff;
  }

  .el-select-dropdown__item {
    height: auto;
    padding: 0 12px;
  }

  .option_list_body {
    .option_list_class {
      padding: 8px;
      display: flex;
      flex-direction: column;

      .option_item_class {
        display: flex;
        flex-direction: row;
        line-height: 30px;
        max-width: 277px;

        .itam_span {
          display: flex;
          margin-right: 10px;
          justify-content: center;
          align-items: center;
        }

        > span:last-child {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    hr {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .selected {
    .option_list_class {
      background: #f9fcff !important;
      border: 1px solid #1890ff !important;
      border-radius: 2px !important;
    }
  }

  .el-select-dropdown__item.selected {
    color: #606266;
    font-weight: 400;
  }

  ul {
    li {
      &:last-child {
        hr {
          display: none;
        }
      }
    }
  }
}
</style>
