/**
 * vuex mutations
 */
import { TEST_MODULE } from './types'

export default {
  /**
   *
   * @param {*} state
   * @param {*} data
   */
  saveTimes(state, data) {
    state.times = data
  },
  /**
   *
   * @param {*} state
   * @param {*} data
   */
  // SAVE_TAB_MENUS(state, data) {
  //   let fullPathList = state.tabMenus.map((item) => item.routerFullPath)
  //   if (
  //     !fullPathList.includes(data.routerFullPath) &&
  //     data.routerFullPath.includes('#/')
  //   ) {
  //     state.tabMenus.push(data)
  //   }
  // },
  /**
   *
   * @param {*} state
   * @param {*} data
   */
  SAVE_SUB_ROUTER_INFO(state, data) {
    state.subRouterInfo = data
  },
}
