/*
 * @Description:
 * @Version: 2.0
 * @Autor: Seven
 * @Date: 2022-02-23 16:20:11
 * @LastEditors: Seven
 * @LastEditTime: 2022-10-18 13:58:30
 */
import { registerMicroApps, setDefaultMountApp, start } from 'qiankun'
// import { Loading } from 'element-ui'
const _apps = {}
const _global = (window['__MF_CONFIG__'] = {})

/**
 * @param {*} apps  微应用启动信息配置
 */
export function registerAndStart(apps) {
  apps.forEach(app => {
    _global[app.name] = {
      activeBase: app.activeRule
    }
  })

  registerMicroApps(apps, {
    async beforeLoad(app) {
      // Loading.service({ fullscreen: true })
    },
    async beforeMount(app) {
      // Loading.service({ fullscreen: false })
    },
    async afterMount(app) {
      _apps[app.name] = app
    },
    async beforeUnmount(app) {
      if (_apps[app.name]) {
        delete _apps[app.name]
      }
    },
    async afterUnmount(app) {}
  })
  // 设置默认加载的微应用
  setDefaultMountApp(apps[0].activeRule)
  start({
    prefetch: false,
    sandbox: false,
    // sandbox: {
    //   strictStyleIsolation: false
    // },
    getPublicPath: entry => {
      let baseUrl = entry
      if (baseUrl.indexOf('://') < 0) {
        baseUrl = location.protocol + '//' + baseUrl.replace(/^\/+/, '')
      }
      if (!baseUrl.endsWith('/')) baseUrl += '/'
      return baseUrl
    }
  })
}

/**
 * 子应用默认接收配置
 * @param {*} vm  vue实例
 * @returns
 */
export function createDefaultAppConfig(vm) {
  return {
    props: {
      portal: {
        getUser() {}
      },
      data: {
        msg: '测试一下'
      }
    }
  }
}
/**
 * qiankun loading
 * @param {*} ctx vue实例
 * @returns
 */
export function setSubAppLoading(ctx) {
  function loader(loading = false) {
    if (ctx && ctx.$children) {
      ctx.$children[0].isLoading = loading
    }
  }
  return {
    loader
  }
}

// 珠海统一平台单点登录
// 示例：http://localhost:9090/login?tokens=e99d1d70-4c1f-487d-8e93-e8957511d054
// 跳转后到哪个模块取决于系统角色权限
// 获取url地址栏参数组装成对象返回
export function genParamsObj() {
  let tempStr = window.location.href.split('?')[1]
  let tempObj = {}
  if (tempStr) {
    let tempArr = tempStr.split('&')
    tempArr.forEach(f => {
      let arr = f.split('=')
      if (arr.length) {
        tempObj[arr[0]] = arr[1] || ''
      }
    })
    sessionStorage.setItem('hrefs', window.location.href.split('?')[0]) // 将当前地址缓存起来，便于之后单点登录失败后跳转到login
    localStorage.setItem('urlParams', JSON.stringify(tempObj))
  } else {
    if (localStorage.getItem('urlParams')) {
      return JSON.parse(localStorage.getItem('urlParams'))
    }
  }

  return tempObj
}
