import { formatList } from 'comm/src/utils/utils'
import { getUserInfo } from 'comm/src/services/login'

// 用户信息相关，userInfos 已接入到 vuex-persist 中进行持久化缓存
export default {
  namespaced: true,
  state: {
    userName: '',
    agentId: '',
    mainMenu: [], // 微应用主应用路由
    menus: [], // 其它子菜单
  },
  getters: {
    userName: (state) => state.userName,
    agentId: (state) => state.agentId,
    mainMenu: (state) => state.mainMenu,
    menus: (state) => state.menus,
  },
  mutations: {
    SET_USER_NAME(state, payload) {
      state.userName = payload
    },
    SET_AGENT_ID(state, payload) {
      state.agentId = payload
    },
    ADD_ROUTES(state, payload) {
      state.mainMenu = payload
    },
    ADD_MENUS(state, payload) {
      state.menus = payload
    },
    //
    loginOut(state) {
      state.userName = ''
      state.agentId = ''
      state.mainMenu = []
      state.menus = []
      sessionStorage.removeItem('token')
      localStorage.removeItem('vuexLocal')
    },
  },
  actions: {
    // 获取用户信息
    async getUserInfo({ commit }) {
      const { code, data } = await getUserInfo()

      if (code === 200) {
        const [mainMenu, menus] = [
          formatList([...data.menuMap.classify, ...data.menuMap.top]), // 头部导航栏
          formatList(data.menuMap.left), // 侧边栏
          // formatButtonList(data.button), // 按钮
        ]
        // console.log(formatList([...data.menuMap.classify, ...data.menuMap.top, ...data.menuMap.left]))
        commit('SET_USER_NAME', data.sysUser.nickname)
        commit('SET_AGENT_ID', data.sysUser.accountId)
        commit('ADD_ROUTES', mainMenu)
        commit('ADD_MENUS', menus)
      }
      // 不管成功还是失败，依然将状态码与数据传出去，保证使用时代码格式保持一致，而不用特地改回.then的方式处理
      return Promise.resolve({ code, data })
    },
  },
}
