<template>
  <el-dialog
    title="提醒"
    append-to-body
    :visible.sync="dialogVisible"
    width="660px"
    :before-close="handleClose"
    @open="handleOpen"
    ref="tx"
    v-dialogDrag
  >
    <div class="service__expires">
      <span>以下项目的服务即将到期，为不影响你的使用，请尽快联系工作人员服务续期！</span>
      <table-list
        :columns="columns"
        :tableData="tableData"
        :spanMe="spanMe"
        height="500"
        ref="tableList"
        :isLoading="isLoading"
        :noPage="true"
      >
      </table-list>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">我知道了</el-button>
    </div>
  </el-dialog>
</template>

<script>
import tableList from './table'
import { formatTime } from 'comm/src/utils/normal/date'
export default {
  name: 'serviceExpires',
  components: {
    tableList
  },
  data() {
    function dateFormatDay(row) {
      let first = formatTime(row.startDate, 'YYYY-MM-DD')
      let end = formatTime(row.expirationDate, 'YYYY-MM-DD')
      if (row.startDate) {
        return `${first} ~ ${end}`
      } else {
        return ''
      }
    }
    return {
      dialogVisible: false,
      dialogwidth: '660px',
      isLoading: false,
      spanMe: {
        mergeRows: [],
        megerColumns: [0, 1]
      },
      columns: [
        {
          width: '100',
          label: '项目编号',
          prop: 'projectNo'
        },
        {
          width: '100',
          label: '项目名称',
          prop: 'projectName'
        },
        {
          width: '100',
          label: '服务',
          prop: 'serviceName'
        },
        {
          width: '220',
          label: '服务期限',
          prop: 'expirationDate',
          formatter: e => {
            return dateFormatDay(e)
          }
        }
      ],
      tableData: { records: [] }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    async initData() {
      let that = this
      this.tableData.records = []
      this.spanMe.mergeRows = []
      this.isLoading = true
      let params = {}
      // const { code, data } = await this.$http.getServiceExpiresData(params)
      let data = JSON.parse(localStorage.getItem('projectList'))
      if (data?.length) {
        localStorage.getItem('SERVICE_WARN') && (this.dialogVisible = true)
        localStorage.removeItem('SERVICE_WARN')
        data.forEach((el, index) => {
          if (el.projectServerVOS && el.projectServerVOS.length) {
            el.projectServerVOS.forEach(ch => {
              that.tableData.records.push({
                projectNo: el.projectNo,
                projectName: el.projectName,
                serviceName: ch.serviceName,
                expirationDate: ch.expirationDate,
                startDate: ch.startDate
              })
            })
            if (index == 0) {
              that.spanMe.mergeRows.push({
                inx: 0,
                merge: el.projectServerVOS.length
              })
            } else {
              that.spanMe.mergeRows.push({
                inx: that.spanMe.mergeRows[index - 1].merge + that.spanMe.mergeRows[index - 1].inx,
                merge: el.projectServerVOS.length
              })
            }
          }
        })
      }
      this.isLoading = false
    },
    handleOpen() {
      const el = this.$refs.tx.$el.querySelector('.el-dialog')
      el.style.left = 0
      el.style.top = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.service__expires {
  height: 500px;
  max-height: 500px;

  > span {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    margin: 0 0 10px 10px;
  }
}

.dialog-footer {
  text-align: center;
}
</style>
