import {
  getPermission,
  getUserInfos,
  getTenantInfos,
  getUserAllInfo,
  getAccountInfos,
} from 'comm/src/api/login.js'
import { treeFormat } from 'comm/src/utils/utils'
// import { addAccessRoutes } from '../../../base/src/utils/auth'
export const initSysData = {
  methods: {
    //  获取账户相关信息
    async getAccountInfos(cb) {
      const { code, data, message } = await getAccountInfos({ platform: 'web' })
      if (code === 200 && data) {
        if (JSON.stringify(data.menuMap) === '{}') {
          this.$message.error('此账号未授权，请先联系管理员授权后再登录！')
          sessionStorage.removeItem('token')
          setTimeout(() => {
            window.location.href = '/'
          }, 1000)
          return false
        }
        localStorage.setItem('roles', JSON.stringify(data.sysRoles))
        sessionStorage.setItem('permissions', JSON.stringify(data.permissions))
        if (data.sysUser) {
          let userInfo = {
            id: data.sysUser.id,
            userName: data.sysUser.nickname,
            deptName:
              data.depts.length &&
              Object.keys(data.depts[0]).length &&
              data.depts[0].deptName,
          }
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
          localStorage.setItem('userId', data.sysUser.id)
          localStorage.setItem('accountId', data.sysUser.accountId)
          localStorage.setItem(
            'userName',
            JSON.stringify(data.sysUser.nickname)
          )
          localStorage.setItem('userImg', data.sysUser.headImgUrl)
          localStorage.setItem('TENANTID', data.sysUser.tenantId)
          // 判定当前账号是否是初次登录
          if (data.sysUser.status === 0) {
            sessionStorage.setItem('first', 'true')
          }
        }
        if (data.sysTenant) {
          let tenantLnglat = {
            center: data.sysTenant.centerPoint,
            topRight: data.sysTenant.lowerRightCornerPoint,
            leftButtom: data.sysTenant.topLeftCornerPoint,
          }
          sessionStorage.setItem('tenantLnglat', JSON.stringify(tenantLnglat))
        }

        localStorage.setItem('tenantForms', JSON.stringify(data.tenantForms))

        localStorage.setItem('projectList', JSON.stringify(data.projects))
        if (data.menuMap) {
          data.menuMap.classify = data.menuMap.classify || []
          data.menuMap.top = data.menuMap.top || []
          // , btns
          const [classify, menus] = [
            this.formatList([...data.menuMap.classify, ...data.menuMap.top]), // 头部导航栏
            this.formatList(data.menuMap.left), // 侧边栏
          ]
          // 站内消息需要从导航栏触发
          let otherMenu = data.menuMap.left.find(
            (v) => v.path === 'msgInnerManagement'
          )
          localStorage.setItem('otherMenu', JSON.stringify(otherMenu)) //不从菜单也可以触发的tabs
          localStorage.setItem('classify', JSON.stringify(classify)) // 缓存头部导航栏数据
          localStorage.setItem('menus', JSON.stringify(menus)) // 缓存侧边栏数据
          // localStorage.setItem('btns', JSON.stringify(btns)) // 缓存按钮权限数据
          localStorage.setItem(
            'currentSys',
            JSON.stringify(this.getFirstLink(classify))
          )
          this.getFirstLink(classify).path === 'sysManagement' &&
            localStorage.setItem('SERVICE_WARN', true)
          cb()
          // addAccessRoutes(classify, this.$router)
          // this.$router.push({ path: '/' })
          // location.href = `/${this.getFirstLink(classify).path}/${
          //   this.getFirstLink(classify).path
          // }.html`
        }
        if (data.permissions) {
          const btns = this.formatButtonList(data.permissions) // 按钮
          localStorage.setItem('btns', JSON.stringify(btns)) // 缓存按钮权限数据
        }
        // 系统顶部菜单数据
        if (data.userUsedMenuVOS) {
          localStorage.setItem('topMenus', JSON.stringify(data.userUsedMenuVOS))
        }
        // 租户相关系统保存到session
        if (data.tenantInfo) {
          sessionStorage.setItem('tenantInfo', JSON.stringify(data.tenantInfo))
        }
      } else {
        this.$message({ type: 'error', message })
      }
    },
    // 获取用户所有相关信息
    async getUserAllInfo() {
      const { code, data } = await getUserAllInfo()
      if (code === 200) {
        let userInfo = {
          id: data.id,
          userName: data.nickname,
          deptName:
            data.depts.length &&
            Object.keys(data.depts[0]).length &&
            data.depts[0].deptName,
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
      }
    },
    // 获取用户信息
    async getUserInfos() {
      this.getTenantInfosData()
      const { code, data } = await getUserInfos()
      if (code === 200) {
        localStorage.setItem('userId', data.id)
        localStorage.setItem('accountId', data.accountId)
        localStorage.setItem('roles', JSON.stringify(data.roles))
        localStorage.setItem('userName', JSON.stringify(data.nickname))
        localStorage.setItem('userImg', data.headImgUrl)
        sessionStorage.setItem('permissions', JSON.stringify(data.permissions))
        localStorage.setItem('TENANTID', data.tenantId)
        //用户拥有的权限
        if (data.status === 0) {
          sessionStorage.setItem('first', 'true')
        } else {
          sessionStorage.setItem('first', 'false')
        }
      }
    },
    // 获取租户信息
    async getTenantInfosData() {
      const { code, data } = await getTenantInfos()
      if (code === 200) {
        let tenantLnglat = {
          center: data.centerPoint,
          topRight: data.lowerRightCornerPoint,
          leftButtom: data.topLeftCornerPoint,
        }
        sessionStorage.setItem('tenantLnglat', JSON.stringify(tenantLnglat))
        await this.getPermissions()
      }
    },
    // 获取权限
    async getPermissions() {
      const { data, code } = await getPermission({ platform: 'web' })
      if (code === 200) {
        if (!data) {
          this.$message.error('此账号未授权，请先联系管理员授权后再登录！')
          sessionStorage.removeItem('token')
          setTimeout(() => {
            window.location.href = '/'
          }, 1000)
          return false
        }
        data.classify = data.classify || []
        data.top = data.top || []
        const [classify, menus, btns] = [
          this.formatList([...data.classify, ...data.top]), // 头部导航栏
          this.formatList(data.left), // 侧边栏
          this.formatButtonList(data.button), // 按钮
        ]
        // 站内消息需要从导航栏触发
        let otherMenu = data.left.find((v) => v.path === 'msgInnerManagement')
        localStorage.setItem('otherMenu', JSON.stringify(otherMenu)) //不从菜单也可以触发的tabs
        localStorage.setItem('classify', JSON.stringify(classify)) // 缓存头部导航栏数据
        localStorage.setItem('menus', JSON.stringify(menus)) // 缓存侧边栏数据
        localStorage.setItem('btns', JSON.stringify(btns)) // 缓存按钮权限数据
        localStorage.setItem(
          'currentSys',
          JSON.stringify(this.getFirstLink(classify))
        )
        console.log(this.getFirstLink(classify).path)
        this.getFirstLink(classify).path === 'sysManagement' &&
          localStorage.setItem('SERVICE_WARN', true)
        // cb()
        // addAccessRoutes(classify, this.$router)
        // this.$router.push({ path: '/' })
      }
    },
    // 寻找第一个跳转路径, 并跳转
    getFirstLink(menu) {
      const currentSys = menu.find((val) => {
        console.log(val)
        return val.children
      })
      return currentSys.children[0]
    },
    // 格式化数组，转化为树结构
    formatList(list) {
      if (!Array.isArray(list)) throw new Error('list不是数组!')
      return treeFormat(list, 'id', 'parentId', 'children')
    },
    /**
     * @description 格式化按钮数据
     * @param {Array}
     * @return {Array}
     */
    formatButtonList(btnList) {
      if (!Array.isArray(btnList)) return
      const parentIds = [
        ...new Set(
          btnList.map((item) => {
            return item.parentId
          })
        ),
      ] // 取出所有parentId并去重
      return parentIds.map((item) => {
        return {
          pid: item,
          btns: btnList.filter((val) => {
            return item === val.parentId
          }),
        }
      })
    },
  },
}
