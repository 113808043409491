<!-- 不知道这个能怎么造数据显示出来 -->
<template>
  <v-dialog
    width="800px"
    title="详情"
    close-btn-text="关 闭"
    :close-on-click-modal="false"
    @click.stop="() => {}"
    @closed="$emit('closed')"
  >
    <div class="msg-content-wrap" v-loading="loading">
      <div class="msg-title">{{ messageData.title }}</div>
      <div class="create-time">{{ messageData.createTime }}</div>
      <div class="msg-content" v-html="messageData.noticeContent"></div>

      <div class="file-list">
        <div class="file-batch-download">
          <span>附件({{ messageData.noticeFileList.length }})</span>
          <as-button v-show="messageData.noticeFileList.length" type="text" @click="downFile">
            批量下载
          </as-button>
        </div>
        <ul>
          <li class="file-item" v-for="item of messageData.noticeFileList" :key="item.fileId">
            <p class="file-name">{{ item.fileName }}</p>
            <as-button type="text" @click="downFile(item)">下载</as-button>
          </li>
        </ul>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import VDialog from './VDialog.vue'
import { formatTime } from 'comm/src/utils/normal/date'
import { manageService } from 'comm/src/api/system.js'

export default {
  props: {
    msgData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    VDialog
  },
  data() {
    return {
      messageData: {
        noticeFileList: []
      },
      loading: false
    }
  },
  created() {
    this.getInMailRowData()
  },
  methods: {
    // 毕竟以前没直接对接过，不确定后台返回的数据格式都有没有处理，格式化一遍防止有报错
    formatData(data) {
      return {
        title: `【${data.noticeTypeName}】 ${data.noticeName}`,
        createTime: formatTime(data.createTime, 'YYYY-MM-DD HH:mm:ss'),
        noticeFileList: data.noticeFileList || [],
        noticeContent: data.noticeContent || ''
      }
    },
    downFile(file) {
      const files = file ? [file] : this.messageData.noticeFileList

      for (let f of files) {
        let elem = document.createElement('a')
        elem.setAttribute('href', '/' + f.filePath)
        elem.setAttribute('download', f.fileName)
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
      }
    },
    async getInMailRowData() {
      this.loading = true
      const { code, data } = await manageService.getInMailRowData(this.msgData.recordId)
      this.loading = false

      if (code === 200) {
        this.messageData = this.formatData(data)
        this.delMainMessage()
      }
    },
    // 删除站内消息
    async delMainMessage() {
      const ids = [this.msgData.id]
      const { code, message } = await manageService.inMailMessageDel({ ids, type: 3 }, 'POST')
      if (code === 200) {
        this.$emit('refresh')
      } else {
        this.$message.error(message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.msg-title {
  margin-bottom: 10px;
  white-space: nowrap;
}

.create-time {
  margin-bottom: 8px;
  padding-bottom: 10px;
  font-size: 12px;
  color: #ddd;
  border-bottom: 1px solid #ddd;
}

.msg-content-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.msg-content {
  min-height: 200px;
  flex: 1;
  line-height: 24px;
  overflow-y: auto;
}

.file-list {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .file-batch-download {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .el-button {
    padding: 0;
    margin-left: 10px;
  }
}

.file-item {
  display: flex;

  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/deep/ {
  .el-dialog__body {
    display: flex;
    overflow: hidden;
  }
}
</style>
