<template>
  <div class="my-table">
    <el-table
      :data="tableData.records"
      border
      :style="{ width: tbwidth }"
      :height="tbHeight"
      :row-key="rowKey"
      :empty-text="emptyText"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :span-method="arraySpanMethod"
      @select="select"
      @select-all="select"
      v-loading="isLoading"
      element-loading-background="rgba(0,0,0,0)"
      ref="ansoTable"
      :show-header="showHeader"
      :cell-style="lineHeight"
      v-if="changClum"
      :header-row-style="returnHeader"
    >
      <template v-for="item in columnsCopy">
        <el-table-column
          v-if="item.type === 'index'"
          width="50"
          type="index"
          label="序号"
          align="center"
          :key="item.prop"
          :resizable="item.resizable === undefined ? true : item.resizable"
          :index="
            index => {
              return index + 1 + (pageIndex - 1) * pageSize
            }
          "
        >
        </el-table-column>
        <el-table-column
          v-else-if="item.type === 'selection'"
          width="55"
          type="selection"
          align="center"
          :key="item.prop"
          :resizable="item.resizable === undefined ? true : item.resizable"
        >
        </el-table-column>
        <el-table-column
          v-else-if="item.type === 'custom'"
          :width="item.width"
          :key="item.label"
          :label="item.label"
          :fixed="item.fixed"
          :resizable="item.resizable === undefined ? true : item.resizable"
          align="center"
        >
          <template slot-scope="scope">
            <slot :name="item.prop" :row="scope.row" :index="scope.$index"></slot>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type === 'expand'"
          :width="item.width"
          type="expand"
          :key="item.label"
          :label="item.label"
          :resizable="item.resizable === undefined ? true : item.resizable"
          :fixed="item.fixed"
          align="center"
        >
          <template slot-scope="scope">
            <slot :name="item.prop" :row="scope.row"></slot>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :min-width="item.width"
          :key="item.prop"
          :resizable="item.resizable === undefined ? true : item.resizable"
          :prop="item.prop"
          :label="item.label"
          :show-overflow-tooltip="true"
          :formatter="item.formatter"
          :align="item.align || 'center'"
          :sortable="item.sortable"
          :fixed="item.fixed"
        >
        </el-table-column>
      </template>
    </el-table>
    <div class="my-page" v-if="!noPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[100, 200, 300]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import tableBar from './tableBar.vue'
export default {
  components: {
    // tableBar
  },
  props: {
    columns: Array,
    tableData: Object,
    isLoading: Boolean,
    height: String,
    noPage: Boolean,
    rowKey: String,
    spanMe: Object,
    emptyText: {
      default: '暂无数据',
      required: false,
      type: String
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    reduceNum: {
      type: Number,
      default: 100
    },
    pageIndex: Number,
    pageSize: Number
  },
  data() {
    return {
      tbwidth: '100%',
      tbHeight: 'auto',
      offsetTop: 0,
      // pageIndex: 1,
      // pageSize: 100, //每页条数
      total: 0, //总条数
      // selectData: [], //选中行
      lineHeight: {
        padding: '4px 0px !important;'
      },
      changClum: true,
      columnsCopy: this.columns
    }
  },
  computed: mapState({
    coms: state => state.tabs
  }),
  mounted() {
    let that = this
    this.init()
    if (!this.height) {
      window.onresize = () => {
        if (!that.height) {
          let index = this.coms.findIndex(v => v.visible)
          let nowDom = document.querySelectorAll('.content')[index + 1].querySelector('.my-table')
          if (nowDom) {
            that.offsetTop = nowDom.offsetTop
            let winHeight = document.body.clientHeight
            that.tbHeight = winHeight - that.offsetTop - this.reduceNum + 'px'
            console.log(
              `hei:${that.tbHeight},winh:${winHeight},top:${that.offsetTop},reduceNum:${that.reduceNum}`
            )
          }
        } else {
          that.tbHeight = that.height
        }
        // 使用此方法强制更新组件时，此组件不能有子组件否则子组件不会被更新
        that.$nextTick(() => {
          that.$forceUpdate()
          that.$refs.ansoTable.doLayout()
        })
      }
    }
  },
  watch: {
    tableData: {
      deep: true,
      handler(n, o) {
        this.total = Number(n.total)
      }
    },
    coms: {
      deep: true,
      handler(val) {
        let num = 0
        setTimeout(() => {
          num = num + 1
          let index = this.coms.findIndex(v => v.visible)
          if (num === index + 1) {
            let nowDom = document.querySelectorAll('.content')[index + 1].querySelector('.my-table')
            if (nowDom && location.href.indexOf('sysManagement' > -1)) {
              // if (num === index + 1) {
              this.init()
              this.$refs.ansoTable.doLayout()
              // }
            }
          }
        }, 200)
        // }
      }
    }
  },
  methods: {
    // 表格初始化
    init() {
      let that = this
      setTimeout(() => {
        if (!that.height) {
          let index = this.coms.findIndex(v => v.visible)
          let nowDom = document.querySelectorAll('.content')[index + 1].querySelector('.my-table')
          if (nowDom) {
            that.offsetTop = nowDom.offsetTop
            let winHeight = document.body.clientHeight
            that.tbHeight = winHeight - that.offsetTop - this.reduceNum + 'px'
            console.log(
              `hei:${that.tbHeight},winh:${winHeight},top:${that.offsetTop},reduceNum:${that.reduceNum}`
            )
          }
        } else {
          that.tbHeight = that.height
        }
        that.total = Number(that.tableData.total)
      }, 100)
    },
    updateTable() {
      let num = 0
      setTimeout(() => {
        num = num + 1
        let index = this.coms.findIndex(v => v.visible)
        if (num === index + 1) {
          let nowDom = document.querySelectorAll('.content')[index + 1].querySelector('.my-table')
          if (nowDom && location.href.indexOf('sysManagement' > -1)) {
            // if (num === index + 1) {
            this.init()
            this.$refs.ansoTable.doLayout()
            // }
          }
        }
      }, 200)
    },
    // update() {
    // 表格需要重新布局，否则会出现错乱
    // let that = this
    // this.eventHub.$on(`doLayout`, (val) => {
    //   setTimeout(() => {
    //     let index = this.coms.findIndex((v) => v.visible)
    //     let nowDom = document
    //       .querySelectorAll('.content')
    //       [index + 1].querySelector('.my-table')
    //     if (nowDom && location.href.indexOf('sysManagement' > -1)) {
    //       if (that.$refs.ansoTable) {
    //         that.$refs.ansoTable.doLayout()
    //       }
    //       this.init()
    //     }
    //   }, 200)
    // })
    // },
    handleSizeChange(val) {
      // this.pageSize = val
      this.$emit('update:pageSize', val)
      this.$emit('update:pageIndex', 1)
      this.$emit('change')
    },
    handleCurrentChange(val) {
      // this.pageIndex = val
      this.$emit('update:pageIndex', val)
      this.$emit('change')
    },
    // 当前选中行
    select(selection, row) {
      // this.selectData = selection
      if (this.$attrs.selectData) this.$emit('update:selectData', selection)
    },
    refreshTable() {
      this.$emit('change')
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      let defaultObj = {
        rowspan: 0,
        colspan: 0
      }
      // 开启合并行列
      if (this.spanMe && JSON.stringify(this.spanMe) !== '{}') {
        if (
          this.spanMe.mergeRows &&
          this.spanMe.mergeRows.length &&
          this.spanMe.megerColumns.length
        ) {
          if (this.spanMe.megerColumns.includes(columnIndex)) {
            let o = this.spanMe.mergeRows.find(el => {
              return el.inx === rowIndex
            })
            if (o) {
              return {
                rowspan: o.merge,
                colspan: 1
              }
            } else {
              return {
                rowspan: 0,
                colspan: 0
              }
            }
          }
        }
      }
    },
    returnHeader() {
      return { height: '33px' }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-class {
  box-shadow: 10px 10px 5px #888;
}

.my-table {
  padding: 0 8px;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 5px;
}
</style>
<style lang="scss">
// 提示框宽度限制
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>
