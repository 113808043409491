<template>
  <div id="main-root" class="main-root">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="scss">
html,
body,
#main-root {
  height: 100%;
}
</style>
