import { defaultData } from 'comm/src/config/index'
/**
 * 设置静态页面站点logo及title
 * @function setWebsiteIconAndTitle
 * @param {String}  iconUrl - logourl地址
 * @param {String}  titleName - 静态页面的标题
 * @returns null
 * @example
 * import setWebsiteIconAndTitle from 'comm/src/setWebsiteIconAndTitle'
 * setWebsiteIconAndTitle('url', 'name')
 */
function setWebsiteIconAndTitle(iconUrl, titleName) {
  let pageData = JSON.parse(localStorage.getItem('pagedata'))
  let link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'icon'
  link.href =
    iconUrl || (pageData && pageData.websitIcon) || defaultData.websitIcon
  document.getElementsByTagName('head')[0].appendChild(link)
  document.title =
    titleName || (pageData && pageData.platformName) || defaultData.platformName
}
export default setWebsiteIconAndTitle
