import CryptoJS from 'crypto-js'
// DES加密
export const encryp = (str, key = 'e#2sy^2u') => {
  let keyHex = CryptoJS.enc.Utf8.parse(key)
  let encrypted = CryptoJS.DES.encrypt(str, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

// DES解密
export const decrypt = (str, key = 'e#2sy^2u') => {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(str) // base64 或 hex
    },
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  )
  return decrypted.toString(CryptoJS.enc.Utf8)
}
