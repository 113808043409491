// 应用配置
import Vue from 'vue'
import App from '../App.vue'
import router from '../router'
// import store from '../store'
import store from '../store'
import { renderApp } from 'comm/src/utils'
import { ele } from 'plugins'
import { reqInit } from 'comm/src/plugins/req'
import 'plugins/styles'
import 'comm/src/assets/css/common.scss'
import 'comm/src/assets/font/mobile/iconfont.css'
import 'comm/src/assets/font/iconfont.css'
import { registDirective } from 'comm/src/directives'
// import { initComponent } from 'comm/src/components'
import auth from './auth'
const eventHub = new Vue()
Vue.config.productionTip = false
// 初始化请求函数
reqInit(eventHub, ele.Message)
// initComponent(Vue)
registDirective(Vue)

auth(router, store)
export default function () {
  renderApp(Vue, App, {
    router,
    store,
    plugins: [ele],
    fromWeb: 'main',
    el: '#app',
    init: vm => {
      // vm.use of vm.prototype.xxx =xxx
    }
  })
}
