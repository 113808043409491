import { authUrlPre, userUrlPre, smsImgUrl } from 'comm/src/config'
import { reqService } from 'comm/src/plugins/request'
const loginUrl = authUrlPre + '/oauth/token'
const changePasswordUrl = userUrlPre + '/user/resetPassword'
const getPermissionUrl = userUrlPre + '/menus/current'
const getUserInfosUrl = userUrlPre + '/user/current'
const tenantInfo = userUrlPre + '/tenant/current' // 租户信息
const userInfoUrl = userUrlPre + '/user/getUserInfo' // 获取用户信息
const accountInfoUrl = userUrlPre + '/user/info' // 获取账户信息
const passPortSsoUrl = '/api/uaa/passport-sso' // 统一平台单点登录
// 登录
export const login = async (data) => {
  const res = await reqService({
    method: 'POST',
    url: loginUrl,
    params: data,
  })
  return res
}

// 用户自己修改密码
export const changePassword = async (data) => {
  const res = await reqService({
    method: 'PUT',
    url: changePasswordUrl,
    params: data,
  })
  return res
}

// 获取用户权限
export const getPermission = (data) => {
  return reqService({
    method: 'GET',
    url: getPermissionUrl,
    params: data,
  })
}
// 获取验证码
export const getVisible = () => {
  return reqService({
    method: 'GET',
    url: smsImgUrl,
  })
}
export const getUserInfos = () => {
  return reqService({
    method: 'GET',
    url: getUserInfosUrl,
  })
}

// 获取租户信息
export const getTenantInfos = async () => {
  return await reqService({
    method: 'GET',
    url: tenantInfo,
  })
}

export const getUserAllInfo = async (params) => {
  return await reqService({
    method: 'GET',
    url: userInfoUrl,
    params,
  })
}
// 获取账户相关信息
export const getAccountInfos = (data) => {
  return reqService({
    method: 'GET',
    url: accountInfoUrl,
    params: data,
  })
}
// 统一平台单点登录
export const passportSso = (data) => {
  return reqService({
    method: 'POST',
    url: passPortSsoUrl,
    data,
  })
}
