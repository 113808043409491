<!-- qr组件 -->
<template>
  <el-popover
    v-if="qrCodeData.length"
    placement="bottom"
    width="120"
    trigger="hover"
    popper-class="mobile_qrcode"
  >
    <div slot="default">
      <div v-for="item of qrCodeData" :key="item.id" class="qrcode_class">
        <span>{{ `${item.appName}-${item.platform}` }}</span>
        <qriously
          :value="
            (item.requestUrl ? item.requestUrl : domain) +
            `/api/file/downloadFile?fileId=${item.fileId}`
          "
          :size="120"
          :backgroundAlpha="1"
        ></qriously>
      </div>
    </div>
    <i class="el-icon-mobile-phone" slot="reference"></i>
  </el-popover>
</template>

<script>
import Vue from 'vue'
import { manageService } from 'comm/src/api/system.js'
import VueQriously from 'vue-qriously' // 这插件着实牛逼，只提供全局注册，就不去污染基座入口了
Vue.use(VueQriously)

export default {
  data() {
    return {
      qrCodeData: [],
      domain: window.location.origin
    }
  },
  created() {
    this.getNewQrCodeUrl()
  },
  methods: {
    async getNewQrCodeUrl() {
      const { code, data } = await manageService.getNewQrCodeUrl()
      if (code === 200 && data) this.qrCodeData = data
    }
  }
}
</script>

<style lang="scss" scoped>
.el-icon-mobile-phone {
  font-size: 20px;
}
</style>
