/**
 * 路由配置
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Layout from '@/views/Layout'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 静态路由 - 无需鉴权
export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/', // 临时不鉴权方案 , 鉴权的时候改成 / 即可
    name: 'home',
    component: Layout,
    meta: {
      permission: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error_pages/404.vue'),
    hidden: true
  }
]

// 动态路由 - 需要登录鉴权，外部通过concat来添加路由，留着是为了开发页面的时候，即使没权限也能直接写进异步路由里来强制打开页面
export const asyncRoutes = []

// 404页面，必须在异步路由添加后加到路由最后一个
export const _404Routes = [{ path: '*', redirect: '/404', hidden: true }]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
