import { TEST_MODULE } from './types'
/**
 * vuex mutations
 */
export default {
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  [TEST_MODULE.ACTIONS]() { },

  // SAVE_PAGE_TITLE(state, data) {
  //   state.pageTitle = data
  // }
}
