<!-- 用户修改头像，基本逻辑复用新余，主要变更为使用公用弹窗 -->
<template>
  <v-dialog
    testid="header_modifyPwdDialog_1600918255387"
    width="680px"
    title="修改头像"
    :close-on-click-modal="false"
    @closed="$emit('closed')"
  >
    <div class="h-all">
      <!-- 当前头像 -->
      <section class="h-now">
        <p class="label">当前头像</p>
        <vueCropper
          ref="cropper"
          :img="option.img"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :canMoveBox="option.canMoveBox"
          :enlarge="option.enlarge"
          :autoCrop="option.autoCrop"
          :info="option.info"
          :centerBox="option.centerBox"
          :fixedBox="option.fixedBox"
          :full="option.full"
          :outputType="option.outputType"
        />
      </section>
      <!-- 上传头像 -->
      <section class="up-out">
        <div class="up-default">
          <div
            v-for="(item, index) in defaultData"
            :key="item.url"
            :class="[activeIndex === index ? 'activeItem' : '', 'list-img-item']"
            @click="handleImgChecked(item, index)"
          >
            <img :src="item.url" />
          </div>
        </div>
        <el-upload
          action="#"
          list-type="picture-card"
          :auto-upload="false"
          :file-list="fileList"
          :on-success="handleOnSuccess"
          :on-error="handleOnError"
          :on-change="handleOnChange"
          :limit="2"
          :class="[ctp ? 'u-up-img1' : 'u-up-img2']"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div
            slot="file"
            slot-scope="{ file }"
            class="img-item"
            :class="[activeIndex === defaultData.length ? 'activeItem' : '']"
            @click="handleImgChecked(file, defaultData.length)"
          >
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          </div>
        </el-upload>
      </section>
    </div>
    <el-button slot="submit" type="primary" @click="submitImg">提交</el-button>
  </v-dialog>
</template>
<script>
import VDialog from './VDialog.vue'
import { VueCropper } from 'vue-cropper'
import tx1 from '@/assets/img/tx1.png'
import tx2 from '@/assets/img/tx2.png'
import tx3 from '@/assets/img/tx3.png'
import { manageService } from 'comm/src/api/system.js'

export default {
  components: {
    VueCropper,
    VDialog
  },
  data() {
    return {
      imgVisible: false,
      fileList: [],
      imgurl: '',
      defaultData: [],
      activeIndex: 0,
      ctp: false,
      option: {
        img: '',
        autoCropWidth: 100,
        autoCropHeight: 100,
        canMoveBox: false,
        autoCrop: true,
        info: true,
        centerBox: false,
        fixedBox: true,
        full: true,
        outputType: 'png'
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.imgVisible = true
      this.activeIndex = 0
      this.option.img = tx1
      this.fileList = []
      let originImg = localStorage.getItem('userImg')
      if (originImg && originImg !== 'null') {
        let imgUrl = window.location.origin + `/api/file/downloadFile?fileId=${originImg}`
        this.defaultData = [{ url: tx1 }, { url: tx2 }, { url: tx3 }, { url: imgUrl }]
        this.activeIndex = 3
        this.option.img = imgUrl
        this.ctp = true
      } else {
        this.activeIndex = 0
        this.option.img = tx1
        this.defaultData = [{ url: tx1 }, { url: tx2 }, { url: tx3 }]
        this.ctp = false
      }
    },
    handleImgChecked(item, index) {
      this.activeIndex = index
      this.option.img = item.url
    },
    // 上传成功
    handleOnSuccess(response, file, fileList) {
      console.log(file)
    },
    // 上传失败
    handleOnError(err, file, fileList) {},
    // 文件状态改变
    handleOnChange(file, fileList) {
      if (this.defaultData.length === 4) {
        this.defaultData.splice(3, 1)
      }

      if (fileList.length === 2) {
        fileList.splice(0, 1)
      }
      if (this.activeIndex === 3) {
        this.option.img = fileList[0].url
      }
      this.fileList = fileList
      this.ctp = false
    },
    // 删除
    handleRemove(file) {
      if (this.activeIndex === this.defaultData.length) {
        this.activeIndex = ''
        // this.imgurl = ''
        this.option.img = ''
      }
      this.fileList = []
    },
    // 提交上传
    submitImg() {
      let _self = this
      this.$refs.cropper.startCrop()
      this.$refs.cropper.getCropData(data => {
        // let formData = new FormData()
        // formData.append('avatar', data)
        // this.uploadImg(formData)
        let file = _self.convertBase64UrlToBlob(data)
        file.name = 'head.jpg'
        let param = new FormData() // 创建form对象
        param.append('file', file, file.name) // 通过append向form对象添加数据
        _self.uploadImg(param)
      })
    },
    // 上传用户头像
    async uploadImg(formData) {
      const { code, data } = await manageService.upload(formData)
      if (code === 200) {
        const res = await manageService.uploadImg({ headImgUrl: data.id })
        if (res.code === 200) {
          this.$message.success('头像修改成功')
          localStorage.setItem('userImg', data.id)
          this.$emit('closed')
          this.$emit('avatorChange')
        }
      }
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(urlData) {
      let bytes = window.atob(urlData.split(',')[1]) //去掉url的头，并转换为byte
      //处理异常,将ascii码小于0的转换为大于0
      let ab = new ArrayBuffer(bytes.length)
      let ia = new Uint8Array(ab)
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/jpeg' })
    }
  }
}
</script>

<style lang="scss" scoped>
.h-all {
  position: relative;
  padding: 40px 25px;
  display: flex;

  .label {
    position: absolute;
    top: 0;
    left: 10px;
  }

  .h-now {
    background: #f5f5f5;
    width: 200px;
    height: 200px;
    margin-top: 10px;

    p {
      margin: 10px 10px 5px 14px;
    }

    .now-img {
      width: 210px;
      height: 210px;
      border-radius: 50%;
      margin: 0 auto;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .up-out {
    padding-left: 25px;
    flex: 1;
    height: 270px;
    overflow-y: auto;

    .u-up-img1 {
      position: absolute;
      top: 160px;
      left: 360px;
    }

    .u-up-img2 {
      position: absolute;
      top: 160px;
      left: 250px;
    }

    .activeItem {
      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        border: 15px solid #1890ff;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-radius: 4px;
      }

      &::after {
        content: '';
        width: 5px;
        height: 10px;
        position: absolute;
        right: 6px;
        top: 2px;
        border: 2px solid #fff;
        border-top-color: transparent;
        border-left-color: transparent;
        transform: rotate(45deg);
      }
    }

    .up-default {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;
    }

    .list-img-item {
      width: 100px;
      height: 100px;
      margin: 10px 10px 0 0;
      position: relative;
      background: #d4d9de;
      // padding: 5px;
      border-radius: 4px;

      img {
        width: 100%;
      }
    }
  }

  /deep/ {
    .up-out {
      .el-upload--picture-card,
      .el-upload-list__item {
        width: 100px;
        height: 100px;
        line-height: 100px;
      }
    }
  }
}
</style>
