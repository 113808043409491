import { urlPre } from 'comm/src/config'
import { reqService } from 'comm/src/plugins/request'
const authUrlPre = urlPre + '/auth' // 校验相关的请求路径的前缀
const getTokenParamsUrl = authUrlPre + '/jwt/agedToken' // 单点登录获取token的接口
const bindTelUrl = authUrlPre + '/user/bindTel' // 绑定手机
const verificationCodeUrl = authUrlPre + '/verificationCode/getByLoginName' // 根据登录名称发送登录验证码
const loginUrl = authUrlPre + '/jwt/token' // 登录
const getPermissionUrl = authUrlPre + '/user/get' // 获取权限信息
const passwordUrl = authUrlPre + '/user/password' // 修改密码
const logoutUrl = `${urlPre}/uaa/oauth/remove/token` //authUrlPre + '/jwt/signOut' // 登出
const uploadFileUrl = urlPre + '/common/file/uploadFile' // 上传文件
const signOutUrl = urlPre + '/uaa/tokens/logout' // 退出登录
const getTenantListUrl = urlPre + '/user/user/user-tenants' // 获取租户列表地址
const updateTenantListUrl = urlPre + '/uaa/change-login/change-tenant' // 切换租户列表地址
const saveTopTabUrl = urlPre + '/user/menus/saveOrUpdateTopMenu' // 保存顶部页签
const getTopTabDataUrl = urlPre + '/user/menus/findMyUsedMenu' // 获取顶部页签数据
const cimg = urlPre + '/user/user/headImgUrl' // 修改头像
const user = urlPre + '/user'
const dmaUpmsPre = urlPre + '/dma-area' // dma平台请求前缀
// 分区组织树
const orgTreeUrl = dmaUpmsPre + '/info/area-dma-tree'
/**
 * @description 获取组织下拉列表
 */

export const getTreeSelect = async (params) => {
  const res = await reqService({
    method: 'GET',
    url: `${user}/dept/tree-select`,
    params,
  })
  return res
}

//分区组织树,按需给数据
export const getOrgTree = (params) => {
  return reqService({
    method: 'GET',
    url: orgTreeUrl,
    params,
  })
}

/**
 * @description 获取组织用户列表
 */

export const getListData = async (params) => {
  const res = await reqService({
    method: 'GET',
    url: `${user}/user/listUsers`,
    params,
  })
  return res
}
// // 上传图片
// export const uploadFile = async (data) => {
//   const formData = new window.FormData()
//   formData.append('type', 'aaa')
//   formData.append('file', data, data.name || 'img' + (new Date().getTime()))
//   const res = await reqService({
//     method: 'POST',
//     url: uploadFileUrl,
//     data: formData
//   })
//   return res
// }
// // 修改密码
// export const updatePassword = async (data) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: passwordUrl,
//     data: data
//   })
//   return res
// }
// 上传图片
export const uploadFile = async (data) => {
  const formData = new window.FormData()
  formData.append('type', 'aaa')
  formData.append('file', data, data.name || 'img' + new Date().getTime())
  const res = await reqService({
    method: 'POST',
    url: uploadFileUrl,
    data: formData,
  })
  return res
}
// 修改密码
export const updatePassword = async (data) => {
  const res = await reqService({
    method: 'PUT',
    url: passwordUrl,
    data: data,
  })
  return res
}

// 登出
export const logout = async () => {
  const res = await reqService({
    method: 'get',
    url: logoutUrl,
  })
  return res
}

// 获取功能权限信息
export const getFunPermission = async () => {
  const res = await reqService({
    method: 'GET',
    url: getPermissionUrl,
  })
  return res
}
// 绑定手机
export const bindTel = async (data) => {
  const res = await reqService({
    method: 'PUT',
    url: bindTelUrl,
    data: data,
  })
  return res
}
// 根据登录名称发送登录验证码
export const getVerificationCode = async (data) => {
  const res = await reqService({
    method: 'GET',
    url: verificationCodeUrl,
    params: data,
  })
  return res
}
// 登录
export const login = async (data) => {
  const res = await reqService({
    method: 'POST',
    url: loginUrl,
    data: data,
  })
  return res
}
// 单点登录获取Token
export const getTokenParams = async (data) => {
  const res = await reqService({
    method: 'POST',
    url: getTokenParamsUrl,
    data: data,
  })
  return res
}
// 退出登录
export const signOut = () => {
  return reqService({
    method: 'POST',
    url: signOutUrl,
  })
}
// 获取租户列表
export const getTenantList = (params) => {
  return reqService({
    method: 'GET',
    url: getTenantListUrl,
    params,
  })
}
// 租户切换
export const updateTenantList = (params) => {
  return reqService({
    method: 'POST',
    url: updateTenantListUrl,
    data: params,
  })
}
// 保存顶部页签数据
export const updateTopTabData = (params) => {
  return reqService({
    method: 'POST',
    url: saveTopTabUrl,
    data: params,
  })
}
// 获取顶部页签数据
export const getTopTabData = (params) => {
  return reqService({
    method: 'GET',
    url: getTopTabDataUrl,
    params,
  })
}
// 修改头像
export const changeImg = async (prams) => {
  return await reqService({
    method: 'PUT',
    url: cimg,
    data: prams,
  })
}
// 设备水表检索api
export const searchDevice = async (params, url) => {
  return await reqService({
    method: 'GET',
    url,
    params,
  })
}

/**
 * @description 获取班组列表
 */

export const getTeamId = async (id) => {
  const res = await reqService({
    method: 'GET',
    url: `${user}/team/${id}`,
  })
  return res
}

/**
 * @description 获取班组列表
 */

export const getTeamListData = async (params) => {
  const res = await reqService({
    method: 'POST',
    url: `${user}/team/listTeam`,
    data: params,
  })
  return res
}

/**
 * @description 获取组织部门
 */

export const postDepthList = async (data) => {
  const res = await reqService({
    method: 'POST',
    url: `/api/user/dept/listDept`,
    data,
  })
  return res
}
