import { render, staticRenderFns } from "./msgDialog.vue?vue&type=template&id=be8a4d04&scoped=true"
import script from "./msgDialog.vue?vue&type=script&lang=js"
export * from "./msgDialog.vue?vue&type=script&lang=js"
import style0 from "./msgDialog.vue?vue&type=style&index=0&id=be8a4d04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be8a4d04",
  null
  
)

export default component.exports