/**
 * 初始化请求方法
 */
import { tokenKey, tokenType } from 'comm/src/config'
import { Message } from 'element-ui'
import { reqService } from './request'

// 消息提示只出一个
function showMessageOnce(message, type = 'error', duration = 2000) {
  let hasMessage = document.body.querySelector('.el-message')
  if (message && !hasMessage) {
    Message({ message: message, type, duration })
  }
}

const loginOut = (message = '会话失效，请重新登录！') => {
  sessionStorage.removeItem('token')
  setTimeout(() => {
    showMessageOnce(message, 'warning')
    location.href = '/'
  }, 3000)
}

export function reqInit(eventHub, msgFnc) {
  reqService.ansoInitReq({
    successCode: 200,
    tokenInvalidCode: [400, 401, 403],
    reqInterceptor: (config) => {
      config.headers['TW-Authorization'] = localStorage.getItem('token')
      const dev = process.env.VUE_APP_DEV
      if (dev) config.headers['ribbon-version'] = dev //dev // 版本号
      function hasToken() {
        // 校验是否有token
        const accessToken = sessionStorage.getItem('token')
        if (!accessToken) {
          return false
        } else {
          return JSON.parse(accessToken)
        }
      }
      // 设置鉴权信息
      config.headers.Authorization = hasToken()
        ? `${hasToken()[tokenType]} ${hasToken()[tokenKey]}`
        : 'Basic HeSOVcmaqxiuOSu+3pvFalVwIkfBuRW8X5CHLRX6bcY='
    },
    showError: (message, response) => {
      if (message === '登录失效') {
        loginOut()
      }
      // console.log('response', response)
      // 接口白名单,不报错
      const whiteApi = [
        '/api/message-center/noticeuserstatus/webClassifyList',
        // '/api/workflow/process/dataPermissionTree',
        '/api/imis-upms/permission/listPermissionData',
        '/api/rtm-base/permission/list',
        '/api/outworkBusiness/permission/list',
        '/api/workflow/process/dataPermissionTree',
        '/api/dma-area/info/findAreaDmaList',
      ]
      if (!response || !whiteApi.includes(response.config.url)) {
        message && showMessageOnce(message)
      }
    },
    linkToLogin() {
      // console.log('报错')
      // location.href = '/'
      loginOut()
    },
  })
}

export { loginOut }
