import { authUrlPre } from './commApiConf'

import platformLogo from 'comm/src/assets/img/logo1.png'
import loginLogo from 'comm/src/assets/img/logo.png'
import banner1 from 'comm/src/assets/img/banner.jpg'
import banner2 from 'comm/src/assets/img/banner2.jpg'
import banner3 from 'comm/src/assets/img/banner3.jpg'

export const GET_TOKEN_KEY = 'token' // 当前保存token的key
export const TOEKN_TYPE = 'token_type'
export const tokenKey = 'access_token'
export const tokenType = 'token_type'
export const GET_DEVICE_ID_KEY = 'deviceId' // 当前保存deviceId的key
export const GET_USER_INFOS_KEY = 'userInfos' // 当前保存用户信息的key
export const HEADER_TOKEN_KEY = 'Authorization' // 写入请求头的token对应key
export const RES_ERROR_CODE = -1 // 请求失败代码
export const RES_OK_CODE = 0 // 请求成功代码
// 设备id
export const deviceId = guid() //'1F3BA4BE-A3B8-49EF-B3EF-702B71C119A4'
export const smsImgUrl = `${authUrlPre}/validata/code/${deviceId}` // 初始化验证码
export const downloadFileUrl =
  (process.env.VUE_APP_BASEURL || '') + '/api/common/file/download' // 下载文件
export const defaultData = {
  platformName: 'ThinkWater云知水®智慧供水云平台',
  welcomeSpeech: '欢迎进入ThinkWater云知水®智慧供水云平台',
  websitIcon: '/favicon.ico',
  platformIcon: platformLogo,
  logoIcon: loginLogo,
  loginImg: [banner1, banner2, banner3],
  loginUrl: window.location.origin,
}

export const tenantId = localStorage.getItem('TENANTID')
// 生成随机序列
function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
