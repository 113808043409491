export default {
  /**
   *
   * @param {*} state
   * @returns
   */
  test(state) {
    return state.test
  },
}
