// import { USER_ID, USER_INFO, TOKEN } from 'comm/src/utils/getUserInfo'
import NProgress from 'nprogress'
import Layout from '../views/Layout'
import { GET_TOKEN_KEY, GET_USER_INFOS_KEY } from 'comm/src/config'
import { _404Routes, asyncRoutes } from '@/router'
import commStore from 'comm/src/store'
import { bearArr } from 'comm/src/utils/subRouterPermission'
// import actions from './actions'
/**
 *
 * @param {*} menuList
 * @param {*} router
 */
export function addAccessRoutes(menuList, router) {
  // 目前只获取子应用
  const allApps = bearArr(menuList, 'children')
  const formatMenuList = allApps
    .map(item => ({
      path: `/${item.path}`,
      component: Layout,
      name: item.path,
      meta: {
        title: `${item.menuName}`,
        permission: true
      }
    }))
    .concat(asyncRoutes)
  const routes = formatMenuList.concat(_404Routes)
  router.addRoutes(routes)
}

function auth(router) {
  // 已有用户数据，填充动态路由（页面刷新）
  const classifyStr = localStorage.getItem('classify')
  const classifyList = classifyStr ? JSON.parse(classifyStr) : []
  if (classifyList.length) {
    addAccessRoutes(classifyList, router)
  }
  router.beforeEach(async (to, from, next) => {
    // console.log('base_router_to', to)
    const token = sessionStorage.getItem(GET_TOKEN_KEY)
    const userInfos = localStorage.getItem('userId')
    NProgress.start()
    if (token) {
      if (userInfos) {
        if (to.path === '/login') {
          const redirect = to.query?.redirect || '/'
          next({ path: redirect })
        } else {
          sessionStorage.setItem('currentApp', to.path)
          next()
        }
        NProgress.done()
      } else {
        // 静默获取用户信息，如果失败则需要用户重新登录
        // const { code, data } = await commStore.dispatch(
        //   `${GET_USER_INFOS_KEY}/getUserInfo`
        // )
        // if (code === 200) {
        //   addAccessRoutes(data.menuMap.classify, router)
        //   sessionStorage.setItem('currentApp', to.path)
        //   next({ ...to, replace: true })
        // } else {
        //   console.log('获取用户信息失败')
        //   // await store.dispatch('user/resetToken')
        //   // Message.error(error || 'Has Error')
        //   next(`/login?redirect=${to.path}`)
        //   NProgress.done()
        // }
      }
    } else {
      // 无需鉴权的页面
      if (to.matched.length > 0 && !to.matched.some(record => record.meta.permission)) {
        sessionStorage.setItem('currentApp', to.path)
        next()
        NProgress.done()
      } else {
        if (!localStorage.getItem('urlParams')) {
          // 登录页记录来源，登陆后判断权限，如果有该页权限则重定向到该页
          next(`/login?redirect=${to.path}`)
        } else {
          next()
        }
      }
    }
  })

  router.afterEach(() => {
    // console.log('AAAAAAAAAFter', to)
    // store.commit('SAVE_TAB_MENUS', {routerFullPath: to.fullPath})
    NProgress.done()
  })
}

export default auth
