<template>
  <el-popover
    width="400"
    trigger="click"
    placement="bottom"
    popper-class="layout-msg-popover"
    :visible-arrow="false"
  >
    <div class="layout-msg-list" v-if="msgList.length">
      <el-menu
        class="message-header"
        mode="horizontal"
        :default-active="msgActiveName"
        @select="msgHandleClick"
      >
        <el-menu-item v-for="msg in msgTabs" :index="msg.code" :key="msg.code">
          {{ msg.name }}
        </el-menu-item>
      </el-menu>

      <ul class="msg-list">
        <li class="msg-item" v-for="item in msgList" :key="item.id" @click.stop="checkDetail(item)">
          <div class="msg-item-left">
            <p :title="item.title">
              <span :class="[+item.status ? 'msg-yes' : 'msg-no']"></span>{{ item.title }}
            </p>
            <p class="pd-l" v-html="item.noticeContent"></p>
            <p class="msg-date">{{ item.sendTime }}</p>
          </div>
          <div class="msg-item-right el-icon-arrow-right" />
        </li>
      </ul>
    </div>
    <div class="no-data" v-else>无消息记录</div>
    <el-badge slot="reference" class="layout-message" :value="msgCount" :max="99">
      <i class="el-icon-bell" />
    </el-badge>

    <msg-dialog
      v-if="isShowMsgDialog"
      :msgData="msgData"
      @click.native.stop
      @closed="isShowMsgDialog = false"
      @refresh="getMsgNoReadData"
    />
  </el-popover>
</template>

<script>
import { formatTime } from 'comm/src/utils/normal/date'
import { manageService } from 'comm/src/api/system.js'
import MsgDialog from './msgDialog.vue'

export default {
  props: {
    allSystems: {
      type: Array,
      default: () => []
    }
  },
  components: {
    MsgDialog
  },
  computed: {
    workflowCenter() {
      return this.allSystems.find(system => system.path === 'workflowCenter')
    }
  },
  data() {
    return {
      msgCount: 0,
      msgTabs: [],
      msgList: [],
      msgData: {},
      msgActiveName: null,
      isShowMsgDialog: false
    }
  },
  created() {
    this.getMsgNoReadData()
  },
  methods: {
    msgHandleClick(code) {
      this.msgActiveName = code
      this.getMsgNoReadData()
    },
    // 查看信息详情
    checkDetail(params) {
      const isWorkNotice = params.noticeTypeCode.includes('worknotice')

      if (isWorkNotice) {
        // 工单消息，判断是否有工单系统权限
        if (this.workflowCenter) {
          this.delMainMessage(params)
        } else {
          this.$message({ type: 'info', message: '无权限访问！' })
        }
      } else {
        // 非工单消息，打开详情弹窗
        this.msgData = params
        this.isShowMsgDialog = true
      }
    },
    // 删除站内消息
    async delMainMessage(params) {
      const ids = [params.id]
      const { code, message } = await manageService.inMailMessageDel({ ids, type: 3 }, 'POST')

      if (code === 200) {
        this.$emit('select', this.workflowCenter, 'workflowCenter#/workflowManage/Todo')
        this.getMsgNoReadData()
      } else {
        this.$message.error(message)
      }
    },
    // 获取站内消息未读信息
    async getMsgNoReadData() {
      let params = { parentCode: this.msgActiveName }
      const { code, data } = await manageService.getNoReadMsgData(params)

      if (code === 200 && data && Object.keys(data).length) {
        if (!this.msgTabs.length) {
          this.msgTabs = [{ code: null, name: '全部' }].concat(
            data.noticeTypeList.filter(item => item)
          )
        }
        const messeageList = data.messeageList || []
        this.msgCount = messeageList.length
        this.msgList = messeageList.map(m => {
          m.noticeContent = m.noticeContent || ''

          return {
            id: m.userStatusId,
            title: `【${m.noticeTypeName}】 ${m.noticeName}`,
            status: m.status,
            recordId: m.recordId,
            noticeTypeCode: m.noticeTypeCode,
            sendTime: formatTime(m.sendTime, 'YYYY-MM-DD HH:mm:ss'),
            noticeContent: m.noticeContent.replace(
              /<\/?(br|p|b|i|del|strong|em|u|img|a)[.]*[^>]*>/gi, // 去除特殊符号，以一行展示
              ''
            )
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.layout-msg-popover {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 60px);
}
</style>

<style lang="scss" scoped>
.layout-msg-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  .msg-list {
    flex: 1;
    overflow-y: auto;
  }

  .msg-item {
    display: flex;
    padding: 10px;
    align-items: center;
    line-height: 24px;
    cursor: pointer;

    &:nth-child(n + 2) {
      border-top: 1px solid #ddd;
    }
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }

    .msg-yes {
      background: #ddd;
    }

    .msg-no {
      background: red;
    }
  }

  .pd-l {
    padding-left: 8px;
  }

  .msg-date {
    padding-left: 15px;
    color: #ddd;
  }

  .msg-item-left {
    flex: 1;
    overflow: hidden;
  }
}

.no-data {
  text-align: center;
}

.message-header {
  display: flex;
  margin-bottom: 8px;
  flex-shrink: 0;
  overflow-x: auto; // 不清楚以前业务，但之前没有做滚动，感觉还是加上保险

  .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}

.layout-message {
  margin-right: 10px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  cursor: pointer;
}
</style>
