<!-- 应用管理弹出层 -->
<template>
  <transition name="slide-fade">
    <div class="application-container" v-if="visible">
      <div class="application-search">
        <el-input
          v-model="searchValue"
          testid="header_inputSysName_1600918255386"
          clearable
          size="small"
          placeholder="搜索系统名称、功能名称（如：移动抄表）"
        >
          <el-button
            slot="append"
            size="small"
            testid="header_searchSysName_1600918255386"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <el-button
          @click="closeDialog"
          style="color: #fff"
          type="text"
          icon="el-icon-close"
          circle
        ></el-button>
      </div>
      <div class="application-system-list">
        <div v-for="application in searchApplicationData" class="system-list" :key="application.id">
          <h1 class="title">
            <img
              v-if="application.css && validateUrl(application.css)"
              class="img-icon img-icon-l"
              :src="application.css"
            />
            <i v-else class="icon iconfont" :class="application.css"></i>
            {{ application.menuName }}
          </h1>
          <div
            class="item"
            v-for="system in application.children"
            :key="system.id"
            :testid="`header_${system.menuName}_1600918255386`"
            @click="handleLinkTo(system)"
          >
            <img
              v-if="system.css && validateUrl(system.css)"
              class="img-icon img-icon-s"
              :src="system.css"
            />
            <i v-else class="icon iconfont" :class="system.css"></i>
            {{ system.menuName }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { cloneDeep } from 'comm/src/utils/utils.js'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    allPlat: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    // 过滤具备搜索文字的平台
    searchApplicationData() {
      const applicationData = cloneDeep(this.allPlat)

      if (this.searchValue) {
        return applicationData
          .map(system => {
            if (!system.children) system.children = []
            const children = system.children.filter(child =>
              child.menuName.includes(this.searchValue)
            )
            system.children = children.length ? children : []
            return system
          })
          .filter(item => item.children.length)
      }

      return applicationData
    }
  },
  data() {
    return {
      searchValue: ''
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    // 照搬新余，无法确定他们原来是怎么判断的，如果以点来判断这里逻辑是有问题的
    validateUrl(url) {
      return url.indexOf('.') > -1
    },
    handleLinkTo(systemMenu) {
      this.$emit('select', systemMenu)
      this.closeDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
