<!-- 框架层搜索栏，原新余仅提供远传使用，基本逻辑样式照搬新余 -->
<template>
  <el-select
    v-model="dmaSearchKey"
    :remote-method="dmaRemoteMethod"
    :loading="loading"
    class="layout-search"
    filterable
    remote
    reserve-keyword
    placeholder="区域编码/名称/别名、DMA名称、考核表号"
    popper-class="layout-search-popper"
  >
    <i slot="prefix" class="iconfont icon-sousuo"></i>
    <el-option
      v-for="item in dmaIdOptions"
      :key="item.id"
      :label="item.areaName"
      :value="item.id"
      @click.native="dmaIdChange(item)"
    >
      <template>
        <div class="option_list_body">
          <div class="option_list_class">
            <div class="option_item_class">
              <span class="itam_span"><i class="el-icon-s-data"></i></span>
              <span
                ><b>{{ areaLevel(item.areaLevel, item.areaType) }}</b></span
              >
            </div>
            <div class="option_item_class">
              <span
                >{{ item.areaType === 1 ? '区域编码' : 'DMA编码' }}<span>：</span
                >{{ item.areaCode }}</span
              >
            </div>
            <div class="option_item_class">
              <span>{{ item.areaType === 1 ? '区域名称' : 'DMA名称' }}：{{ item.areaName }}</span>
            </div>
          </div>
          <hr />
        </div>
      </template>
    </el-option>
  </el-select>
</template>
<script>
import { setSessionStorageTabs } from 'comm/src/utils/utils'
import { getOrgTree } from 'comm/src/api/user.js'
export default {
  name: 'dmaChartIndex',
  data() {
    return {
      dmaSearchKey: '',
      loading: false,
      dmaIdOptions: []
    }
  },
  methods: {
    async dmaRemoteMethod(query) {
      if (query !== '') {
        this.loading = true
        let { code, data } = await getOrgTree({
          // constructState: '1,2,3',
          searchKey: query,
          needMeter: false,
          needMonitorMeter: false
        })
        if (code === 200) {
          this.loading = false
          this.dmaIdOptions = data
        }
      } else {
        this.dmaIdOptions = []
      }
    },
    dmaIdChange(item) {
      if (item.areaType === 1) {
        const params = {
          id: item.id,
          menuName: item.areaName,
          menuType: 'left',
          tabName: item.areaName,
          path: 'areaChartIndex',
          platform: 'web',
          params: item
        }
        console.log('areaChartIndex', params)
        setSessionStorageTabs(params)
        this.$router.push(`${this.$route.path}#/areaChartIndex/${item.id}/${item.areaName}`)
        // item.path = 'areaChartIndex'
        // this.$store.dispatch('goAreaPage', item)
      } else if (item.areaType === 2) {
        const params = {
          id: item.id,
          menuName: item.areaName,
          menuType: 'left',
          tabName: item.areaName,
          path: 'dmaChartIndex',
          platform: 'web',
          params: item
        }
        console.log('dmaChartIndex', params)
        setSessionStorageTabs(params)
        this.$router.push(`${this.$route.path}#/dmaChartIndex/${item.id}/${item.areaName}`)
      }
    },
    areaLevel(val, type) {
      let resultVal = ''
      if (type === 1) {
        switch (val) {
          case 0:
            resultVal = '新余水务'
            break
          case 1:
            resultVal = '一级分区'
            break
          case 2:
            resultVal = '二级分区'
            break
          case 3:
            resultVal = '三级分区'
            break
          case 4:
            resultVal = '四级分区'
            break
          case 5:
            resultVal = '五级分区'
            break
          case 6:
            resultVal = '六级分区'
            break
          case 7:
            resultVal = '七级分区'
            break
        }
      } else {
        resultVal = 'DMA分区'
      }
      return resultVal
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-search {
  margin-right: 10px;

  /deep/ {
    .el-input__inner {
      width: 321px;
      height: 28px;
      line-height: 28px;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
      font-size: 12px !important;
      padding-left: 10px !important;
    }

    .el-input__prefix {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      width: calc(100% - 10px) !important;

      i {
        position: absolute;
        right: 5px;
      }
    }
  }
}
</style>

<style lang="scss">
.layout-search-popper {
  .el-select-dropdown__wrap {
    max-height: 77.8vh;
  }

  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #fff;
  }

  .el-select-dropdown__item {
    height: auto;
    padding: 0 12px;
  }

  .option_list_body {
    .option_list_class {
      padding: 8px;
      display: flex;
      flex-direction: column;

      .option_item_class {
        display: flex;
        flex-direction: row;
        line-height: 30px;
        max-width: 277px;

        .itam_span {
          display: flex;
          margin-right: 10px;
          justify-content: center;
          align-items: center;
        }

        > span:last-child {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    hr {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .selected {
    .option_list_class {
      background: #f9fcff !important;
      border: 1px solid #1890ff !important;
      border-radius: 2px !important;
    }
  }

  .el-select-dropdown__item.selected {
    color: #606266;
    font-weight: 400;
  }

  ul {
    li {
      &:last-child {
        hr {
          display: none;
        }
      }
    }
  }
}
</style>
