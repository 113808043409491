<!-- 编辑快捷访问系统弹出层 -->
<template>
  <div class="edit-container">
    <div class="fixed-menu-tips">固定导航栏({{ fixedMenuLength }})</div>

    <el-checkbox-group
      class="application-system-list"
      v-model="checkedList"
      :max="8"
      @change="handleChangeCustom"
    >
      <div v-for="application in allPlat" class="system-list" :key="application.id">
        <h1 class="title">
          <img
            v-if="application.css && validateUrl(application.css)"
            class="img-icon img-icon-l"
            :src="application.css"
          />
          <i v-else class="icon iconfont" :class="application.css"></i>
          {{ application.menuName }}
        </h1>
        <div>
          <el-checkbox
            class="system-item"
            v-for="system in application.children"
            value-key="id"
            :testid="`header_${system.name}_1600918255387`"
            :key="system.id"
            :label="system"
          >
            {{ system.menuName }}
          </el-checkbox>
        </div>
      </div>
    </el-checkbox-group>

    <div class="btn-list">
      <el-button type="primary" size="small" @click="saveApplication">保存</el-button>
      <el-button size="small" @click="handleCancal">取消</el-button>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'comm/src/utils/utils.js'
import { setLocalStorage } from 'comm/src/utils/normal/localStorage.js'
import { updateTopTabData } from 'comm/src/api/user'

export default {
  props: {
    customMenus: {
      type: Array,
      default: () => []
    },
    allPlat: {
      type: Array,
      default: () => []
    },
    allSystems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    fixedMenuLength() {
      return `${this.checkedList.length}/${this.allSystems.length}`
    }
  },
  data() {
    return {
      fixedMenu: cloneDeep(this.customMenus),
      checkedList: []
    }
  },
  created() {
    this.fillChecked()
  },
  methods: {
    // 填充用户自定义菜单勾选
    fillChecked() {
      this.checkedList = this.allSystems.filter(item =>
        this.customMenus.find(system => system.id === item.id)
      )
    },
    handleChangeCustom(menus) {
      this.$emit('change', menus)
    },
    handleCancal() {
      this.handleChangeCustom(this.fixedMenu)
      this.$emit('close')
    },
    // 照搬新余，无法确定他们原来是怎么判断的，如果以点来判断这里逻辑是有问题的
    validateUrl(url) {
      return url.indexOf('.') > -1
    },
    async saveApplication() {
      const params = this.customMenus.map((menu, inxdex) => ({
        menuId: menu.id,
        sort: inxdex
      }))

      const { code, message } = await updateTopTabData(params)
      if (code === 200) {
        this.$message.success(message)
        // 不知道存起来干嘛，但还是还原这个存储的数据
        setLocalStorage('customSystems', this.editedSystems)
      }

      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';

.edit-container {
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 48px;
  left: 0;
  height: calc(100% - 48px);
  // padding: 10px 100px 0;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.9);
}

.fixed-menu-tips {
  padding: 10px 100px;
  font-size: 14px;
}

.application-system-list {
  flex: 1;
  width: 1100px;
  padding: 0 100px;
}

.system-item {
  display: block;
}

.btn-list {
  line-height: 60px;
  border-top: 1px solid #999;
  text-align: center;
}
</style>
