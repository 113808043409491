<template>
  <div class="layout-header" :style="{ '--head_bg_color': headBgColor }">
    <div class="left">
      <div class="logo-wrap" @click="openUrl" style="cursor: pointer">
        <img :src="platformLogo" />
      </div>
      <div :class="['application', { lock: !disEditStatus }]" testid="header_showAll_1600918255386">
        <span @click="isShowApplication = !isShowApplication">应用管理</span>
        <span @click="isShowApplication = !isShowApplication" class="application-arrow"></span>
        <application-dialog
          :visible="isShowApplication"
          :all-plat="allPlat"
          @closeDialog="closeDialog"
          @select="handleSelectMenu"
        ></application-dialog>
      </div>
      <custom-menu
        v-if="navbarMenus.length"
        :dis-edit-status.sync="disEditStatus"
        :current-menu="currentMenu"
        :navbar-menus="navbarMenus"
        :all-plat="allPlat"
        :all-systems="allSystems"
        :custom-menus.sync="customMenus"
        @select="handleSelectMenu"
      ></custom-menu>
    </div>

    <div class="right">
      <application-search v-if="isShowSearch" />
      <dma-application-search v-if="isDmaSearch" />
      <internal-message :all-systems="allSystems" @select="handleSelectMenu" />
      <qr-popover />
      <user-operate />
    </div>
  </div>
</template>

<script>
/* 静态资源 */
import platformLogo from '@/assets/img/logo1.png'
/* 接口请求 */
// import { manageService } from 'comm/src/api/system.js'
// import { getTopTabData } from 'comm/src/api/user.js'
/* 组件 */
import applicationDialog from './applicationDialog.vue' // 应用菜单弹出层
import customMenu from './customMenu.vue' // 可拖拽自定义菜单
import InternalMessage from './InternalMessage.vue' // 站内信（站内消息）
import QrPopover from './qrPopover.vue' // 二维码
import UserOperate from './userOperate' // 用户操作按钮（头像下拉）
import ApplicationSearch from './applicationSearch.vue' // 顶部搜索栏
import DmaApplicationSearch from './dmaApplicationSearch' // dma顶部搜索栏
/* 方法 */
import { cloneDeep } from 'comm/src/utils/utils.js'
export default {
  props: {
    navbarMenus: {
      type: Array,
      default: () => []
    },
    currentMenu: {
      type: String,
      default: ''
    }
  },
  components: {
    applicationDialog,
    customMenu,
    InternalMessage,
    QrPopover,
    UserOperate,
    ApplicationSearch,
    DmaApplicationSearch
  },
  computed: {
    // 以前的搜索并没有提供外部自定义搜索展示逻辑的功能，因此重构版也不会去解决这个问题，否则现有新余逻辑需要发生变更
    isShowSearch() {
      const showSearchList = ['rtm']
      return showSearchList.includes(this.$route.name)
    },
    isDmaSearch() {
      const showSearchList = ['dma']
      return showSearchList.includes(this.$route.name)
    }
  },
  data() {
    return {
      headBgColor: process.env.VUE_APP_HEADER_TOP_BG
        ? process.env.VUE_APP_HEADER_TOP_BG
        : '#222f3f',
      platformLogo,
      isShowApplication: false,
      disEditStatus: true,
      customMenus: [], // 用户自定义快捷访问菜单列表
      allPlat: [], // 已开发且具有权限的所有应用平台
      allSystems: [] // 已开发且具有权限的所有系统
    }
  },
  watch: {
    // 这个写法是为了解决热更新导致头部逻辑走不到
    navbarMenus: {
      handler(val) {
        if (!val?.length) return
        this.fillAuthMenus()
      },
      immediate: true
    }
  },
  created() {
    this.getPlatformLogo()
  },
  methods: {
    closeDialog() {
      this.isShowApplication = false
    },
    handleMouse(boolean) {
      if (!this.disEditStatus) return
      this.isShowApplication = boolean
    },
    openUrl() {
      localStorage.getItem('returnUrl') && window.open(localStorage.getItem('returnUrl'), '_self')
    },
    // 如果外部layout需要改造，select需要提供其他操作，则需要把写入localStorage的信息放到这里来
    handleSelectMenu(systemMenu, path) {
      this.$emit('select', systemMenu, path)
    },
    // 非原新余权限逻辑，除用户权限，还需要根据已开发系统进行匹配，返回已开发并且有访问权限的系统
    fillAuthMenus() {
      const allPlatData = cloneDeep(JSON.parse(localStorage.getItem('classify')))

      allPlatData.forEach(plat => {
        if (!plat.children) plat.children = []
        const authSystems = []

        plat.children.forEach(system => {
          const matchingSystem = this.navbarMenus.find(router => router.name === system.path)
          if (matchingSystem) {
            // 将新余旧菜单数据与新路由数据合并，作为新的路由菜单数据，防止系统中需要使用
            const mergeMenu = Object.assign({}, matchingSystem, system)
            this.allSystems.push(mergeMenu)
            authSystems.push(mergeMenu)
          }
        })

        if (authSystems.length) {
          plat.children = authSystems
          this.allPlat.push(plat)
        }
      })

      // 格式化数据完成，再匹配后台数据，将框架菜单所有数据统一使用格式化数据
      this.getTopTabData()
    },
    // 获取平台logo 以前调用接口获取数据，现在从local缓存获取
    // async getPlatformLogo() {
    //   const { code, data } = await manageService.getTenantFormInfo()
    //   if (code === 200 && data?.platformLogo) this.platformLogo = data.platformLogo
    // },
    // 获取平台logo
    async getPlatformLogo() {
      const tenantInfoStr = sessionStorage.getItem('tenantInfo')
      const tenantInfoObj = tenantInfoStr ? JSON.parse(tenantInfoStr) : ''
      if (tenantInfoObj && tenantInfoObj?.platformLogo) {
        this.platformLogo = tenantInfoObj.platformLogo
      }
    },
    // 获取系统顶部菜单数据 以前的方法从接口取数据，后面从local取缓存数据来处理
    // async getTopTabData() {
    //   const { code, data } = await getTopTabData()
    //   if (code === 200) {
    //     const customMenusData = Array.isArray(data) ? data.map(plat => plat.sysMenu) : []
    //     let customMenus = []
    //     customMenusData.forEach(menu => {
    //       const system = this.allSystems.find(system => system.id === menu.id)
    //       if (system) customMenus.push(system)
    //     })
    //     this.customMenus = customMenus
    //   }
    // }
    async getTopTabData() {
      const topMenusStr = localStorage.getItem('topMenus')
      const topMenusObj = topMenusStr ? JSON.parse(topMenusStr) : []
      const customMenusData = Array.isArray(topMenusObj)
        ? topMenusObj.map(plat => plat.sysMenu)
        : []
      let customMenus = []
      customMenusData.forEach(menu => {
        const system = this.allSystems.find(system => system.id === menu.id)
        if (system) customMenus.push(system)
      })
      this.customMenus = customMenus
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
