<template>
  <v-dialog
    testid="header_modifyPwdDialog_1600918255387"
    width="580px"
    title="修改密码"
    :close-on-click-modal="false"
    :show-close="!isFirstLoginFlag"
    @closed="$emit('closed')"
  >
    <el-form
      class="monify-password-form"
      ref="ruleForm"
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      validate-on-rule-change
    >
      <p class="tips">温馨提示：为保证账号安全，需要设置8-20位包含字母,数字,特殊字符的组合密码！</p>
      <el-form-item class="password-input" prop="oldPassword" label="旧密码">
        <el-input show-password v-model="ruleForm.oldPassword" placeholder="旧密码" />
      </el-form-item>
      <el-form-item class="password-input new-password" prop="newPassword" label="新密码">
        <el-input show-password v-model="ruleForm.newPassword" placeholder="请输入密码" />
      </el-form-item>
      <el-form-item class="password-input" prop="newPasswordConfrim" label="确认密码">
        <el-input
          show-password
          v-model="ruleForm.newPasswordConfrim"
          placeholder="请再次输入密码"
        />
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: center">
      <el-button v-show="isFirstLoginFlag" size="small" type="primary" @click="$emit('closed')">
        登出
      </el-button>
      <el-button v-show="!isFirstLoginFlag" size="small" type="primary" @click="$emit('closed')">
        取消
      </el-button>
      <el-button size="small" type="primary" @click="submit('ruleForm')"> 确认 </el-button>
    </div>
  </v-dialog>
</template>
<script>
import { validateNoAndStr2 } from 'comm/src/utils/normal/check.js'
import { updatePassword } from 'comm/src/api/common.js'
import VDialog from './VDialog.vue'
const md5 = require('md5')

export default {
  name: 'ModifyPassword',
  components: {
    VDialog
  },
  props: {
    isFirstLoginFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateOld = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'))
      } else if (value.length < 8) {
        // this.newPasswordTipsShow = false
        callback(new Error('旧密码不能小于8位'))
      } else if (!validateNoAndStr2(value)) {
        callback(new Error('旧密码需包含字母和数字'))
      } else {
        callback()
      }
    }

    const validateNew = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (value.length < 8) {
        callback(new Error('新密码不能小于8位'))
        // } else if (!validateNoAndStr2(value)) {
        //   callback(new Error('密码需包含字母和数字'))
      } else if (
        !/^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{6,}$/.test(
          value
        )
      ) {
        callback(new Error('密码应为数字、大小写字母、特殊字符中的至少3种组成'))
      } else if (value === this.ruleForm.oldPassword) {
        callback(new Error('新密码不能与旧密码一致'))
      } else {
        callback()
      }
    }

    const validateSame = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    return {
      visible: false,
      newPasswordTipsShow: true,
      newPasswordWaring: false,
      newPasswordTips: '字母，大小写，特殊字符，字母组合，不少于10个字符',
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfrim: ''
      },
      rules: {
        oldPassword: [{ validator: validateOld, trigger: 'blur' }],
        newPassword: [{ validator: validateNew, trigger: 'blur' }],
        newPasswordConfrim: [{ validator: validateSame, trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 提交修改密码
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.changePasswordPost()
      })
    },
    async changePasswordPost() {
      let params = {
        newPassword: md5(this.ruleForm.newPassword),
        oldPassword: md5(this.ruleForm.oldPassword)
      }
      const { code } = await updatePassword(params)

      if (code === 200) {
        this.$confirm('密码修改成功, 请重新登录！', '提示', {
          confirmButtonText: '重新登录',
          showCancelButton: false,
          closeOnClickModal: false,
          showClose: false,
          closeOnPressEscape: false,
          type: 'success'
        }).then(() => {
          sessionStorage.clear()
          location.href = '/'
          // localStorage.clear()
          // this.$router.push('/login')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.monify-password-form {
  padding: 0 50px;

  .el-form-item {
    margin-bottom: 15px;
  }
}

.tips {
  padding: 14px 0;
}

.custom-dialog {
  /deep/ {
    .el-form-item__label {
      line-height: 32px;
      padding: 0;
    }

    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #eee;
    }

    .el-dialog__footer {
      border-top: 0;
    }
  }
}
</style>
