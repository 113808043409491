const _events = require('events')
// 发布订阅
export const DataBus = (function () {
  const _emitter = new _events()
  /**
   *
   * @param {*} type
   * @param {*} handler
   */
  function on(type, handler) {
    _emitter.on(type, handler)
  }
  /**
   *
   * @param {*} type
   * @param {*} data
   */
  function emit(type, data) {
    _emitter.emit(type, data)
  }
  /**
   *
   * @param {*} type
   */
  function off(type) {
    const handler =
      arguments.length > 1 && arguments[1] !== undefined
        ? arguments[1]
        : function () {}
    _emitter.off(type, handler)
  }
  return {
    on,
    emit,
    off,
  }
})()
