<template>
  <div
    class="login-new"
    v-loading="loginLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <div class="login-left">
      <el-carousel :height="bodyH" :arrow="arrow" :indicator-position="indicator">
        <el-carousel-item v-for="item of defaultData.loginImg" :key="item">
          <img :src="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div
      class="login-right"
      style="
        background: linear-gradient(
          rgb(255, 255, 255, 0.9) 5%,
          rgb(255, 255, 255, 1) 20%,
          rgb(255, 255, 255, 1) 10%,
          rgb(255, 255, 255, 0.9) 80%
        );
      "
    >
      <div class="login-container" v-if="!loginLoading">
        <div class="title">
          <img :src="defaultData.logoIcon" class="tit-img" /><span>{{
            defaultData.welcomeSpeech
          }}</span>
        </div>
        <div class="login-box">
          <div class="login-inserts">
            <el-form
              class="login-form"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-position="top"
              validate-on-rule-change
            >
              <el-form-item label="账号" class="login-input" prop="userName">
                <el-input
                  class="user"
                  placeholder="请输入账号"
                  v-model="ruleForm.userName"
                  @keyup.enter.native="login()"
                />
              </el-form-item>
              <el-form-item label="密码" class="login-input" prop="password">
                <el-input
                  type="password"
                  class="password"
                  show-password
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                  @keyup.enter.native="login()"
                />
              </el-form-item>
              <el-form-item label="验证码" class="login-input" prop="smsCode">
                <el-input
                  class="sms-code"
                  placeholder="验证码"
                  v-model="ruleForm.smsCode"
                  @keyup.enter.native="login"
                >
                  <template slot="append">
                    <img class="sms-img" :src="smsImg" @click="changeImg" v-if="cimg" />
                  </template>
                </el-input>
              </el-form-item>
              <el-checkbox v-model="checked">记住账号</el-checkbox>
              <el-form-item>
                <el-button class="login-btn" type="primary" @click="login" :loading="loading"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
        <p class="login-bom">{{ copyright }}</p>
      </div>
    </div>
    <!-- <local-remove v-if="localRemoveShow" />
    <first-login v-if="firstLoginShow" />
    <bowser-tips v-if="bowserTipsShow" /> -->
  </div>
</template>

<script>
import bg from '@/assets/img/bg.png'
import logo from '@/assets/img/logo.png'
import titleImg from '@/assets/img/img.png'
// import localRemove from './localRemove/index'
// import firstLogin from './firstLogin/index'
// import bowserTips from './bowserTips/index'
import { login, getVisible, passportSso } from '@/api/Login'
import managementServie from 'comm/src/api/login'
import { defaultData, smsImgUrl, deviceId } from 'comm/src/config/index'
import setWebsiteIconAndTitle from 'comm/src/utils/normal/setWebsiteIconAndTitle'
// const { treeFormat } = require('anso-util').default
import { initSysData } from 'comm/src/mixin/initSysData.js'
const md5 = require('md5')
import { addAccessRoutes } from '@/utils/auth.js'
import { genParamsObj, genPassPortParams } from '@/utils/mf'
const urlParams = genParamsObj()
// const passPortParams = genPassPortParams()
const deploy = sessionStorage.getItem('hrefs')
import { decrypt } from 'comm/src/utils/cryptoJs.js'
export default {
  name: 'login',
  components: {
    // localRemove,
    // firstLogin,
    // bowserTips,
  },
  mixins: [initSysData],
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    let validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入用户名'))
      } else {
        callback()
      }
    }
    let validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    return {
      loading: false, // 加载中
      isLogin: false, // 是否已经登录成功
      logo,
      bg,
      titleImg,
      bodyH: '',
      cimg: true,
      // localRemoveShow: false,
      // firstLoginShow: true,
      // bowserTipsShow: false,
      smsImg: smsImgUrl,
      copyright: `${new Date().getFullYear()} © 深圳拓安信物联股份有限公司`,
      ruleForm: {
        userName: '',
        password: '',
        smsCode: ''
      },
      loginLoading: window.location.href.includes('token') ? true : false,
      rules: {
        userName: [{ validator: validateName, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        smsCode: [{ validator: validateCode, trigger: 'blur' }]
      },
      defaultData: defaultData,
      checked: false
    }
  },
  computed: {
    arrow() {
      if (this.defaultData.loginImg.length > 1) {
        return 'always'
      } else {
        return 'never'
      }
    },
    indicator() {
      if (this.defaultData.loginImg.length > 1) {
        return ''
      } else {
        return 'none'
      }
    }
  },
  watch: {
    checked: {
      handler(n) {
        localStorage.setItem('REVERT_ACCOUNT_PWD', n)
        if (n) {
          localStorage.setItem('USER_ACCOUNT', this.ruleForm.userName)
        } else {
          localStorage.removeItem('USER_ACCOUNT')
        }
      }
    }
  },
  created() {
    // sessionStorage.setItem('token', JSON.stringify(''))
    let temp = localStorage.getItem('REVERT_ACCOUNT_PWD') === 'true'
    if (temp) {
      this.ruleForm.userName = localStorage.getItem('USER_ACCOUNT')
    }
    this.checked = temp
    this.initPageData()
    console.log(urlParams, '=========解析链接参数========')
    if (urlParams.token) {
      // 登录页链接跳转
      if (Object.keys(urlParams).length === 1 && Object.keys(urlParams)[0] === 'token') {
        // 珠海单点登录值有token一个参数
        this.passportSso(urlParams.token)
      } else {
        // 清远旧系统登录有多个参数
        this.toNextPage()
      }
    } else if (urlParams.params) {
      // 济南单点登录
      this.passportSso(md5(urlParams.params), urlParams.params)
    }
  },
  mounted() {
    // 监听窗口变化
    this.onresize()
    // 初始化banner高度
    this.initH()
    // 初始化验证码
    // this.changeImg()
  },
  methods: {
    // 初始化banner高度
    initH() {
      this.bodyH = document.body.clientHeight + 'px'
    },
    // 窗口变化触发
    onresize() {
      let that = this
      window.onresize = function () {
        that.bodyH = document.body.clientHeight + 'px'
      }
    },
    async changeImg() {
      this.cimg = false
      await getVisible()
      this.smsImg = this.smsImg + '?tempid=' + Date.now()
      this.cimg = true
    },
    // 登录
    login() {
      if (this.isLogin || this.loading) {
        return
      }
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.loginPost()
        } else {
          return false
        }
      })
    },
    async loginPost() {
      localStorage.clear()
      this.loading = true
      try {
        const query = {
          username: this.ruleForm.userName.trim(),
          password: md5(this.ruleForm.password.trim()),
          validCode: this.ruleForm.smsCode.trim(),
          grant_type: 'password_code',
          deviceId: deviceId
        }
        const { code, data } = await login(query)
        if (code === 200) {
          this.isLogin = true
          // 清除缓存数据
          let pageData = localStorage.getItem('pagedata')
          localStorage.clear()
          sessionStorage.clear()
          pageData && localStorage.setItem('pagedata', pageData)
          console.log('localStorage==========')
          sessionStorage.setItem('token', JSON.stringify(data))
          localStorage.setItem('token', data.access_token)
          sessionStorage.setItem('deviceId', JSON.stringify(deviceId))
          if (this.checked) {
            localStorage.setItem('USER_ACCOUNT', this.ruleForm.userName)
            localStorage.setItem('REVERT_ACCOUNT_PWD', true)
          }

          this.getAccountInfos(() => {
            addAccessRoutes(JSON.parse(localStorage.getItem('classify')), this.$router)
            this.$router.push({ path: '/' })
          })
          // this.getAccountInfos()
          // this.getUserInfos()
          // this.getTenantInfosData()
          // this.getUserAllInfo()
        } else {
          this.changeImg()
        }
      } finally {
        this.loading = false
      }
    },
    // 跳过登录直接进入下一菜单页面
    toNextPage() {
      // 清除缓存数据
      let data = { access_token: urlParams.token, token_type: urlParams.token_type }
      sessionStorage.clear()
      localStorage.clear()
      sessionStorage.setItem('token', JSON.stringify(data))
      localStorage.setItem('token', data.access_token)
      sessionStorage.setItem('deviceId', JSON.stringify(deviceId))
      if (this.checked) {
        localStorage.setItem('USER_ACCOUNT', this.ruleForm.userName)
        localStorage.setItem('REVERT_ACCOUNT_PWD', true)
      }
      localStorage.setItem('urlParams', JSON.stringify(urlParams))
      localStorage.setItem('returnUrl', urlParams.href)
      this.getAccountInfos(() => {
        addAccessRoutes(JSON.parse(localStorage.getItem('classify')), this.$router)
        localStorage.setItem(
          'currentSys',
          JSON.stringify({
            path: urlParams.redirect
          })
        )
        this.$router.push({ path: urlParams.redirect ? `/${urlParams.redirect}` : '/' })
      })
    },
    // 珠海统一平台单点登录
    async passportSso(platformToken,platformParams) {
      localStorage.clear()
      try {
        this.loading = true
        const query = {
          token: platformToken,
          params: platformParams
        }
        const { code, data } = await passportSso(query)
        if (code === 200) {
          this.isLogin = true
          // 清除缓存数据
          localStorage.clear()
          sessionStorage.clear()
          sessionStorage.setItem('token', JSON.stringify(data))
          localStorage.setItem('token', data.access_token)
          sessionStorage.setItem('deviceId', JSON.stringify(deviceId))
          this.getAccountInfos(() => {
            addAccessRoutes(JSON.parse(localStorage.getItem('classify')), this.$router)
            this.$router.push({ path: '/' })
          })
        } else {
          alert('用户无权限，确认后请重新登录')
          window.location.href = `${deploy}`
        }
      } catch (err) {
        alert('用户无权限，确认后请重新登录')
        window.location.href = `${deploy}`
      } finally {
        this.loading = false
      }
    },
    // // 获取用户信息
    // async getUserInfos() {
    //   const { code, data } = await getUserInfos()
    //   if (code === 200) {
    //     await this.getPermissions()
    //     localStorage.setItem('userId', data.id)
    //     localStorage.setItem('accountId', data.accountId)
    //     localStorage.setItem('roles', JSON.stringify(data.roles))
    //     localStorage.setItem('userName', JSON.stringify(data.nickname))
    //     localStorage.setItem('userImg', JSON.stringify(data.headImgUrl))
    //     sessionStorage.setItem('permissions', JSON.stringify(data.permissions))
    //     localStorage.setItem('TENANTID', data.tenantId)
    //     //用户拥有的权限
    //     if (data.status === 0) {
    //       sessionStorage.setItem('first', 'true')
    //     }
    //   }
    // },
    // // 获取权限
    // async getPermissions() {
    //   const { data, code } = await getPermission({ platform: 'web' })
    //   if (code === 200) {
    //     if (!data) {
    //       this.$message.error('此账号未授权，请先联系管理员授权后再登录！')
    //       sessionStorage.removeItem('token')
    //       return false
    //     }
    //     data.classify = data.classify || []
    //     data.top = data.top || []
    //     const [classify, menus, btns] = [
    //       this.formatList([...data.classify, ...data.top]), // 头部导航栏
    //       this.formatList(data.left), // 侧边栏
    //       this.formatButtonList(data.button), // 按钮
    //     ]
    //     localStorage.setItem('classify', JSON.stringify(classify)) // 缓存头部导航栏数据
    //     localStorage.setItem('menus', JSON.stringify(menus)) // 缓存侧边栏数据
    //     localStorage.setItem('btns', JSON.stringify(btns)) // 缓存按钮权限数据
    //     localStorage.setItem(
    //       'currentSys',
    //       JSON.stringify(this.getFirstLink(classify))
    //     )
    //     console.log(this.getFirstLink(classify).path)
    //     this.getFirstLink(classify).path === 'sysManagement' && localStorage.setItem('SERVICE_WARN', true)
    //     location.href = `/${this.getFirstLink(classify).path}/${
    //       this.getFirstLink(classify).path
    //     }.html`

    //     // location.href = `/sysManagement.html`
    //   }
    // },
    // // 寻找第一个跳转路径, 并跳转
    // getFirstLink(menu) {
    //   const currentSys = menu.find((val) => {
    //     console.log(val)
    //     return val.children
    //   })
    //   return currentSys.children[0]
    // },
    // // 格式化数组，转化为树结构
    // formatList(list) {
    //   if (!Array.isArray(list)) throw new Error('list不是数组!')
    //   return treeFormat(list, 'id', 'parentId', 'children')
    // },
    // /**
    //  * @description 格式化按钮数据
    //  * @param {Array}
    //  * @return {Array}
    //  */
    // formatButtonList(btnList) {
    //   if (!Array.isArray(btnList)) return
    //   const parentIds = [
    //     ...new Set(
    //       btnList.map((item) => {
    //         return item.parentId
    //       })
    //     ),
    //   ] // 取出所有parentId并去重
    //   return parentIds.map((item) => {
    //     return {
    //       pid: item,
    //       btns: btnList.filter((val) => {
    //         return item === val.parentId
    //       }),
    //     }
    //   })
    // },
    async initPageData(o) {
      let { code, data } = await managementServie.getPlatFormInfo()
      if (code === 200) {
        data = JSON.parse(decrypt(data))
        console.log('getInfo解密：', data)
        if (data) {
          this.defaultData.loginImg = []
          this.defaultData.platformName = data.platformName
          this.defaultData.welcomeSpeech = data.loginWelcomeMsg
          this.defaultData.websitIcon = data.tenantIcon
          this.defaultData.logoIcon = data.loginLogo
          this.defaultData.platformIcon = data.platformLogo
          data.loginBanner1 && this.defaultData.loginImg.push(data.loginBanner1)
          data.loginBanner2 && this.defaultData.loginImg.push(data.loginBanner2)
          data.loginBanner3 && this.defaultData.loginImg.push(data.loginBanner3)
          data.loginBanner4 && this.defaultData.loginImg.push(data.loginBanner4)
          data.loginBanner5 && this.defaultData.loginImg.push(data.loginBanner5)
          this.defaultData.loginUrl = data.loginUrl
          setWebsiteIconAndTitle(this.defaultData.websitIcon, this.defaultData.platformName)
        }
      }
      localStorage.setItem('pagedata', JSON.stringify(this.defaultData))
    }
  }
}
</script>
<style lang="scss">
@import './index';
</style>
