/**
 * @module request
 * @desc 封装请求的方法
 */
/**
 * 基于axios
 * 1、创建请求实例
 * 2、封装参数配置
 * 3、全局提示
 * 4、不处理业务数据
 * 5、返回请求实例
 */
import axios from 'axios'

// 创建请求实例
const service = axios.create({
  baseURL: '', // url的前缀
  timeout: 60000, // 超时时间 需要覆盖该值的话，在调用axios()方法时，提供timeout参数
})

// 全局提示错误
let showError = null
// 重定向到登录页
let linkToLogin = null
// 请求成功状态码
let RES_OK_CODE = -99999
let reqInterceptor = null // 请求拦截器的逻辑
let resInterceptor = null // 请求拦截器的逻辑
let tokenInvalidCodeList = []
/**
  * 初始化全局的配置项
  * @function ansoInitReq
  * @param { Object } params - 参数对象
  * @param { Function } params.showError - 显示错误信息的方法
  * @param { Function } params.linkToLogin - 重定向到登录页的方法
  * @param { number } params.successCode - 请求成功的业务码
  * @param { string } params.baseURL - 请求路径的前缀
  * @param { number } params.timeout - 超时时间
  * @param { Function } params.reqInterceptor - 请求拦截器的逻辑方法
  * @param { Function } params.resInterceptor - 响应拦截器的逻辑方法
  * @example
 const { reqService } = require('ansoUtil').default
 reqService.ansoInitReq({
   successCode: 0,
   reqInterceptor: (config) => {
     const dev = process.env.VUE_APP_DEV
     if (dev) config.headers.version = dev
     config.headers[tokenKey] = getLocalStorage(tokenKey)
   },
   showError: (message) => {
     ElementUI.Message({
       message,
       type: 'error',
       duration: 2 * 1000
     })
   },
   linkToLogin () {
     Vue.prototype.eventHub.$emit('toLogin')
   }
 })
  */
service.ansoInitReq = ({
  showError: _showError, // 提示错误信息
  linkToLogin: _linkToLogin, // 重定向到登录页
  successCode, // 成功状态码
  baseURL,
  timeout,
  tokenInvalidCode,
  reqInterceptor: _reqInterceptor, // 请求拦截器的逻辑
  resInterceptor: _resInterceptor, // 请求拦截器的逻辑
}) => {
  if (tokenInvalidCode !== undefined) tokenInvalidCodeList = tokenInvalidCode
  if (_reqInterceptor !== undefined) reqInterceptor = _reqInterceptor
  if (_resInterceptor !== undefined) resInterceptor = _resInterceptor
  if (_showError !== undefined) showError = _showError
  if (_linkToLogin !== undefined) linkToLogin = _linkToLogin
  if (successCode !== undefined) RES_OK_CODE = successCode
  if (baseURL !== undefined) {
    service.defaults.baseURL = baseURL
  }
  if (timeout !== undefined) {
    service.defaults.timeout = timeout
  }
}

// 配置请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers['TW-Authorization'] = localStorage.getItem('token')
    if (reqInterceptor) reqInterceptor(config)
    return config
  },
  (error) => {
    if (showError) showError('出错了, 请联系管理员')
    Promise.reject(error) // axios的API规定必须抛出异常
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (resInterceptor) return resInterceptor(response)
    const { code, message, data } = response.data
    if (typeof code === 'undefined') {
      return response
    }
    if (code !== RES_OK_CODE) {
      if (showError) showError(message, response)
      return {
        code,
        message,
      }
    } else {
      return {
        code: RES_OK_CODE,
        data,
        message,
      }
    }
  },
  (error) => {
    // 判断Token 失效
    const status = parseInt(error.message.split('code')[1].trim())
    if (tokenInvalidCodeList.includes(status)) {
      let urlParams = JSON.parse(localStorage.getItem('urlParams'))
      if (urlParams && urlParams.token) {
        location.href = urlParams.href
          ? urlParams.href.match(
              /^((http|https):\/\/)?([\w]+\.?)+(:\d{2,6})?/
            )[0]
          : ''
      } else {
        if (linkToLogin) linkToLogin()
        showError('登录失效')
        if (showError) showError(error.message)
        return
      }
      return {
        code: -9999,
        message: error.message,
      }
    }
  }
)
/**
  * 返回添加了拦截器逻辑的axios实例
  * @function reqService
  * @param { Object } params - 参数对象
  * @param { string } params.method - http请求方法
  * @param { string } params.url - 请求路径
  * @param { Object } params.data - 非get请求的参数对象
  * @param { Object } params.params - get请求的参数对象
  * @return { Object } 返回一个promise对象
  * @example
 const { reqService } = require('ansoUtil').default
 // 还支持axios的其他参数
 reqService({
   method: 'GET',
   url: '',
   data: ''
 })
  */
export const reqService = service

/**
  * 发送多个请求;
  * 请求包含axios中请求前后设置的拦截器;
  * 支持多个不同路径，不同参数的请求;
  * @function reqServiceAll
  * @param { Array } params - 参数对象
  * @param { string } params[].method - http请求方法
  * @param { string } params[].url - 请求路径
  * @param { Object } params[].data - 非get请求的参数对象
  * @param { Object } params[].params - get请求的参数对象
  * @return {false | Array} 返回多个promise对象或者false值
  * @author Ming
  * @time 2019/12/03
  * @example
 const { reqServiceAll } = require('ansoUtil').default
 // 还支持axios的其他参数
 reqServiceAll([
   {
     method: 'GET',
     url: '',
     data: ''
   },
   {
     method: 'GET',
     url: '',
     data: ''
   }
 ])
  */
export const reqServiceAll = (paramsList) => {
  return axios
    .all(
      paramsList.map((item) => {
        return service({
          method: item.method,
          url: item.url,
          [item.method.toUpperCase() === 'GET' ? 'params' : 'data']: item.data,
        })
      })
    )
    .then((response) => {
      return response
    })
    .catch((response) => {
      return false
    })
}

/**
  * post请求导出Excel;暂时不用启用；碰到实际的场景，再根据后端的规范调整；
  * @function postExcel
  * @param { Object } param - 总的参数
  * @param { String } param.fileName - 文件名称
  * @param { Object } param.config - 可变参数对象
  * @returns { Object } 返回响应体数据
  * @author sheldon
  * @time 2020/8/11
  * @example
 const { postExcel } = require('ansoUtil').default
 postExcel({
   fileName: '报表1'
   // 其他参数
 })
  */
export const postExcel = async ({ fileName, ...config }) => {
  const res = await reqService({
    method: 'POST',
    responseType: 'arraybuffer',
    ...config,
  })
  if (res) {
    const uInt8Array = new Uint8Array(res)
    const blob = new window.Blob([uInt8Array], {
      type: 'application/vnd.ms-excel',
    })
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', `${fileName}.xls`)
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
    return res
  }
}

export const getExcel = async ({ fileName, params, url }) => {
  const urlParams = []
  for (const key in params) {
    if (params[key]) {
      urlParams.push(`${key}=${params[key]}`)
    }
  }
  const uri = url + '?' + urlParams.join('&')
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = uri
  tempLink.setAttribute('download', `${fileName}.xls`)
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
}
