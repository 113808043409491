/**
 * 应用指令汇总
 */
// export { default as inputNumber } from './input-number'
import submitClick from 'comm/src/components/directives/submitClick.js'
import dialogDrag from 'comm/src/components/directives/diag.js'

// 外部注册指令
export function registDirective(Vue) {
  Vue.use(submitClick)
  Vue.use(dialogDrag)
}
