<!-- 用户设置操作 -->
<template>
  <el-popover v-model="isShowPopver" placement="bottom-end" trigger="click">
    <el-menu class="user-oprerate-wrap">
      <!-- <el-menu-item>
        <a
          slot="title"
          class="download-doc"
          download="【操作说明书】新余水务供水综合信息管理平台20210422"
          :href="`${publicPath}operation_instruction20210422.doc`"
        >
          下载操作说明书
        </a>
      </el-menu-item> -->
      <el-menu-item @click="isShowChangeAvator = true">修改头像</el-menu-item>
      <el-menu-item
        v-if="!isMaster"
        testid="header_showModifyPassPanel_1600918255387"
        @click="isShowChangePassword = true"
      >
        修改密码
      </el-menu-item>
      <el-menu-item v-if="originData.length > 1">
        <el-popover slot="title" placement="left-start" width="200" trigger="hover">
          <template slot="default">
            <div class="ch-tenant">
              <el-input
                @input="handlefilter"
                maxlength="60"
                v-model="filter"
                clearable
                @clear="handleClear"
                placeholder="请输入租户名称"
              ></el-input>
              <div class="ch-list">
                <div
                  v-for="tenant of tenantList"
                  :class="['tenant-item', { actvie: tenant.id === tenantId }]"
                  :key="tenant.id"
                  @click="tenantChange(tenant.id)"
                >
                  {{ tenant.tenantName }}
                </div>
              </div>
            </div>
          </template>
          <div slot="reference">租户切换</div>
        </el-popover>
      </el-menu-item>
      <el-menu-item testid="header_signOut_1600918255387" @click="signOut">退出</el-menu-item>
    </el-menu>

    <el-row
      class="operate-wrap"
      type="flex"
      testid="header_showUserOperate_1600918255387"
      align="middle"
      slot="reference"
      :title="userName"
    >
      <img class="avator" :src="avatorUrl" />
      <span class="user-name">{{ userName }}</span>
      <i :class="!isShowPopver ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
    </el-row>

    <head-img
      v-if="isShowChangeAvator"
      ref="head"
      @avatorChange="setAvator"
      @closed="isShowChangeAvator = false"
    />

    <modify-password
      :isFirstLoginFlag="isFirstLoginFlag"
      v-if="isShowChangePassword"
      @closed="isShowChangePassword = false"
    />
  </el-popover>
</template>

<script>
import { updateTenantList, signOut } from 'comm/src/api/user.js' // getTenantList 接口不用了
import defaultHeaderImg from '@/assets/img/tx1.png'
import HeadImg from './changeHeadImg' // 变更用户头像
import ModifyPassword from './modifyPassword.vue' // 变更用户密码
import { addAccessRoutes } from '@/utils/auth.js'
import { initSysData } from 'comm/src/mixin/initSysData.js'
const urlParams = JSON.parse(localStorage.getItem('urlParams'))
export default {
  components: {
    HeadImg,
    ModifyPassword
  },
  mixins: [initSysData],
  data() {
    return {
      isFirstLoginFlag: false,
      filter: '',
      originData: [],
      isMaster: false, // 2022-4-1 修改超级管理员也可以修改密码 localStorage.getItem('userId') === '1', // 是否为超级管理员，1为超级管理员，不允许修改密码
      avatorUrl: '',
      userName: JSON.parse(localStorage.getItem('userName')),
      tenantList: [],
      isShowPopver: false, // 显示图标处的下级内容
      publicPath: process.env.NODE_ENV === 'development' ? `${process.env.VUE_APP_BASEURL}/` : './',
      tenantId: localStorage.getItem('TENANTID'),
      isShowChangeAvator: false, // 变更用户头像弹出层
      isShowChangePassword: false // 更换密码弹出层
    }
  },
  created() {
    !(urlParams && urlParams.token) && this.isFirstLogin()
    this.setAvator()
    this.getTenantList()
  },
  watch: {
    isShowChangePassword(val) {
      const isChangePassword = sessionStorage.getItem('first') === 'true'
      if (!val && isChangePassword && !this.isMaster) {
        this.signOut()
      }
    }
  },
  methods: {
    // 以前处于新余框架层中的逻辑，如果没有修改变更过密码，则每次都需要弹出变更密码的弹窗
    isFirstLogin() {
      this.isFirstLoginFlag = false
      const isChangePassword = sessionStorage.getItem('first') === 'true'
      if (isChangePassword && !this.isMaster) {
        this.isShowChangePassword = true
        this.isFirstLoginFlag = true
      } else {
        this.isFirstLoginFlag = false
      }
    },
    // 统一逻辑，以前新余直接判断不等于字符串null，导致头像一直判断为存在而图片加载失败
    // 目前看后台返回结果为空字符串或图片id，不去深究以前逻辑了，直接处理
    setAvator() {
      let userImgId = localStorage.getItem('userImg')
      this.avatorUrl =
        !userImgId || userImgId === 'null'
          ? defaultHeaderImg
          : `${process.env.VUE_APP_BASEURL}/api/file/view?fileId=${userImgId}`
    },
    async tenantChange(tenantId) {
      if (tenantId === this.tenantId) {
        return false
      }
      let deviceId = JSON.parse(sessionStorage.getItem('deviceId'))
      let params = {
        tenantId: tenantId,
        client: 'webApp'
      }
      let temp = localStorage.getItem('REVERT_ACCOUNT_PWD') === 'true'
      let userAccount = localStorage.getItem('USER_ACCOUNT')
      // let userAccount = localStorage.getItem('USER_ACCOUNT')
      let pageData = localStorage.getItem('pagedata')
      const { code, data } = await updateTenantList(params)
      if (code === 200) {
        let temp_token = sessionStorage.getItem('token')
        localStorage.clear()
        sessionStorage.clear()
        temp && localStorage.setItem('REVERT_ACCOUNT_PWD', true)
        pageData && localStorage.setItem('pagedata', pageData)
        sessionStorage.setItem('token', temp_token)
        userAccount && localStorage.setItem('USER_ACCOUNT', userAccount)
        deviceId && sessionStorage.setItem('deviceId', JSON.stringify(deviceId))
        this.getAccountInfos(() => {
          addAccessRoutes(JSON.parse(localStorage.getItem('classify')), this.$router)
          window.location.href = '/'
          // this.$router.push({ path: '/' })
        })
      }
    },
    // async getTenantList() { // 旧方法
    //   const { code, data } = await getTenantList()
    //   if (code === 200) this.tenantList = data
    // },
    async getTenantList() {
      const tenantFormsStr = localStorage.getItem('tenantForms')
      const tenantFormsObj = tenantFormsStr ? JSON.parse(tenantFormsStr) : ''
      if (tenantFormsObj) {
        this.tenantList = tenantFormsObj
        this.originData = tenantFormsObj
      }
    },
    // 退出
    async signOut() {
      // const userAccount = localStorage.getItem('USER_ACCOUNT')
      await signOut()
      this.$message.success('已成功退出登录')
      sessionStorage.clear()
      // localStorage.clear()
      if (urlParams && urlParams.token) {
        location.href = urlParams.href
          ? urlParams.href.match(/^((http|https):\/\/)?([\w]+\.?)+(:\d{2,6})?/)[0]
          : ''
      } else {
        location.href = '/' // 刷页面，清缓存和vuex信息
      }

      // this.$router.push('/login') // 直接写死
    },
    // 搜索租户
    handlefilter() {
      this.filter = this.filter.replace(/[#$%<>&\\*\s]/g, '')
      if (this.filter) {
        this.tenantList = this.originData.filter(v => v.tenantName.indexOf(this.filter) > -1)
      } else {
        this.tenantList = this.originData
      }
    },
    handleClear() {
      this.tenantList = this.originData
    }
  }
}
</script>

<style lang="scss" scoped>
.user-oprerate-wrap {
  border-right: none;

  .download-doc,
  .el-menu-item {
    color: #303133;
  }

  .el-menu-item {
    height: 48px;
    line-height: 48px;
  }

  .download-doc {
    text-decoration: none;
  }
}

.operate-wrap {
  cursor: pointer;

  .avator {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    background: #e1e8fa;
  }

  .user-name {
    font-size: 12px;
    max-width: 60px;
    margin: 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tenant-item {
  padding: 5px;
  cursor: pointer;

  &:hover {
    color: #303133;
    background: #e6f7ff;
  }
}

.actvie {
  color: #fff;
  background: #1890ff;
}
.ch-tenant {
  .ch-list {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  /deep/.el-input__inner {
    height: 28px;
    line-height: 28px;
    margin-bottom: 5px;
  }
  /deep/ .el-input__suffix {
    margin-top: -5px;
  }
}
</style>
