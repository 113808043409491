/**
 * 格式化数组转化为树行结构
 * @param {Array} list
 * @param {String} key
 * @param {String} parentKey
 * @param {String} childKey
 * @return {Array} 格式化数组
 */
export function treeFormat(
  list,
  key = 'id',
  parentKey = 'parentId',
  childKey = 'children'
) {
  const sNodes = cloneDeep(list)
  let ii, jj, rr, tmpMap
  if (Array.isArray(sNodes)) {
    rr = []
    tmpMap = []
    sNodes.map((item) => {
      ii = item
      ii.text = ii.label
      tmpMap[ii[key]] = ii
    })
    sNodes.map((item) => {
      jj = item
      if (tmpMap[jj[parentKey]] && jj[key] !== jj[parentKey]) {
        if (!tmpMap[jj[parentKey]][childKey]) {
          tmpMap[jj[parentKey]][childKey] = []
        }
        tmpMap[jj[parentKey]][childKey].push(jj)
      } else {
        rr.push(jj)
      }
    })
    return rr
  } else {
    return [sNodes]
  }
}

/**
 * 对象深拷贝
 * @function cloneDeep
 * @param { Object } parent - 源对象：这个对象的属性将被拷贝
 * @returns { object } 新对象
 * @example
 * const {  cloneDeep  } = require('ansoUtil').default
 * let obj = { name: 12, age: 3 }
 * cloneDeep(obj)
 **/
export const cloneDeep = (parent) => {
  let proxy = null
  // 把parent对象转换成字符串
  proxy = JSON.stringify(parent)
  // 把字符串转换成对象，这是parent的一个副本
  proxy = JSON.parse(proxy)
  const isArray = proxy instanceof Array
  let child = null
  if (isArray) {
    child = []
    for (const i in proxy) {
      /* eslint-disable no-prototype-builtins */
      if (proxy.hasOwnProperty(i)) {
        child.push(proxy[i])
      }
    }
  } else {
    child = {}
    for (const i in proxy) {
      /* eslint-disable no-prototype-builtins */
      if (proxy.hasOwnProperty(i)) {
        child[i] = proxy[i]
      }
    }
  }
  // 因为proxy是中间对象，可以将它回收掉
  proxy = null
  return child
}

export const formatList = (list) => {
  if (!Array.isArray(list)) throw new Error('list不是数组!')
  return treeFormat(list, 'id', 'parentId', 'children')
}

/**
 * 获取当前传入参数类型
 * @param {Any} param
 */
export function checkType(param) {
  let _t = void 0
  return (
    (_t = typeof param) == 'object'
      ? Object.prototype.toString.call(param).slice(8, -1)
      : _t
  ).toLowerCase()
}

/**
 * 限制输入整数，浮点数
 * @param {*} el 指令所绑定的元素
 * @param {*} binding 指令对象
 * @param {*} vnode vue DIFF 生成的虚拟节点
 */
export function onInput(el, binding, vnode) {
  function handle() {
    // 手动触发数据的双向绑定,解决表单验证问题
    if (vnode.componentInstance) {
      vnode.componentInstance.$emit('input', el.value)
    } else {
      vnode.elm.dispatchEvent(new CustomEvent('input', el.value))
    }
    let val = el.value
    // modifiers为修饰符对象，传入了float，则其float属性为true v-input-number.float="2"
    if (binding.modifiers.float) {
      // 清除"数字"和"."以外的字符
      val = val.replace(/[^\d.]/g, '')
      // 只保留第一个, 清除多余的
      val = val.replace(/\.{2,}/g, '.')
      // 第一个字符如果是.号，则补充前缀0
      val = val.replace(/^\./g, '0.')
      if (typeof binding.value !== 'undefined') {
        // 期望保留的最大小数位数
        let pointKeep = 0
        if (
          typeof binding.value === 'string' ||
          typeof binding.value === 'number'
        ) {
          pointKeep = parseInt(binding.value)
        }
        if (!isNaN(pointKeep)) {
          if (!Number.isInteger(pointKeep) || pointKeep < 0) {
            pointKeep = 0
          }
          const str = '^(\\d+)\\.(\\d{' + pointKeep + '}).*$'
          const reg = new RegExp(str)
          if (pointKeep === 0) {
            // 不需要小数点
            val = val.replace(reg, '$1')
          } else {
            // 通过正则保留小数点后指定的位数
            val = val.replace(reg, '$1.$2')
          }
        }
      } else {
        val = el.value.replace(/[^\d]/g, '')
      }
      el.value = val
    } else {
      el.value = el.value.replace(/[^\d]/g, '')
    }
  }
  return handle
}

/**
 * 跨应用跳转
 * @param {String} url 当前页面的路由
 * @param {Object} data 传递数据
 * @param {String} title 当前页面的标题
 */
export function redirectApp(url) {
  const data =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {}
  const title =
    arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ''
  window.history.pushState(data, title, url)
}

/**
 * 修改路由base
 * @param {String} 微应用名称
 */
export function getBase(name) {
  const mfc = window.__MF_CONFIG__
  const base = mfc && mfc[name] ? mfc[name].activeBase : process.env.BASE_URL
  return base
}

/**
 * 文件导出
 * @param {*} file 文件流
 * @returns
 */
export function downloadFile(file) {
  const { headers, data } = file
  let blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  })
  let downloadElement = document.createElement('a')
  let href = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadElement.href = href
  //获取文件名
  let fileName = headers['content-disposition']
    ? decodeURI(headers['content-disposition'].split('=')[1])
    : '' //处理文件名乱码问题
  downloadElement.download = fileName || '' // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href) // 释放掉blob对象
  return
}

/**
 * 实现多维数组对象的扁平化
 * @param {Array} [obj], 期望转换的数组对象
 * @param {String} key, 关键字
 */
export function flatArr([obj], key) {
  let temp = obj[key]
  let result = [obj]

  if (temp && temp.constructor === Array) {
    // 1.将子级 push 到父级（造成二维数组 [obj, [children]]）
    // 2.递归子级的扁平化（清理子级中的第三维）
    result.push(temp.map((item) => flatArr([item], key)).flat())
  }
  // 清理输出内容中的二维
  return result.flat()
}

// 删除 tabs 对应信息，旧新余存储的公用搜索结果信息，但是目前tabs拥有的是路由菜单信息，需要通过路由菜单信息去匹配获取出session中的数据，这段属于写死的逻辑
export function removetSessionStorageTabs(payload) {
  // console.log('payload', payload)
  const tabs = JSON.parse(sessionStorage.getItem('tabs'))
  const equipmentSn = payload.meta?.name // 该 name 是在 subRouterPermission 写死的覆盖逻辑
  const isHiddenMenu = payload.meta?.isHidden // 菜单是否是隐藏菜单，在meta里手动配置的，不是后台的isHidden
  let reapeatIndex = -1
  // 能取到，证明该tab是详情tab，否则为菜单tab
  if (equipmentSn && isHiddenMenu)
    reapeatIndex = tabs.findIndex(
      (tabInfo) => tabInfo.params.equipmentSn === equipmentSn
    )
  if (reapeatIndex !== -1) {
    tabs.splice(reapeatIndex, 1)
    sessionStorage.setItem('tabs', JSON.stringify(tabs))
  }
}

// 设置 tabs，旧新余存储的公用搜索结果信息
export function setSessionStorageTabs(payload) {
  const tabs = JSON.parse(sessionStorage.getItem('tabs')) || []
  const { id } = payload
  const reapeatIndex = tabs.findIndex((data) => data.id && data.id === id)
  if (reapeatIndex !== -1) tabs[reapeatIndex] = payload
  // 覆盖，虽然好像没啥用，但以后说不定就需要覆盖新的信息
  else tabs.push(payload)
  sessionStorage.setItem('tabs', JSON.stringify(tabs))
}
