/**
 * @module util-localStorage
 */
import lockr from 'lockr'

/**
 * 获取localStorage值
 * @function getLocalStorage
 * @param {String}  key - localStorage的字段值
 * @returns {*} - 返回localStorage值
 * @example
 * import {getLocalStorage} from 'comm/src/normal/localStorage'
 * getLocalStorage('name') // 'sheldon'
 */
export function getLocalStorage(key) {
  return lockr.get(key)
}

/**
 * 设置localStorage值
 * @function setLocalStorage
 * @param {*}  key - localStorage的字段值
 * @returns null
 * @example
 * import {setLocalStorage} from 'comm/src/normal/localStorage'
 * setLocalStorage('name', 'sheldon')
 */
export function setLocalStorage(name, value) {
  return lockr.set(name, value)
}

/**
 * 删除localStorage值
 * @function  removeLocalStorage
 * @param {String} key - localStorage的字段值
 * @returns null
 * @example
 * import {removeLocalStorage} from 'comm/src/normal/localStorage'
 * removeLocalStorage('name')
 */
export function removeLocalStorage(key) {
  return lockr.rm(key)
}
