import { authUrlPre, urlPre, baseUrl, userUrlPre, rtmUpmsPre } from 'comm/src/config'
import { reqService } from 'comm/src/plugins/request'
const passwordUrl = userUrlPre + '/user/resetPassword' // 修改密码
const logoutUrl = `${authUrlPre}/oauth/remove/token` // authUrlPre + '/jwt/signOut' 登出
const uploadFileUrl = urlPre + '/common/file/uploadFile' // 上传文件
const searchHistoryUrl = urlPre + '/currency/search/searchHistory' // 站点树搜索记录

//全局搜索（目前只有新余有该功能，但又放到了layout中处理）
export const pageGlobalUrl = rtmUpmsPre + '/remoteMeter/pageGlobal'

// 上传图片
export const uploadFile = async (data) => {
  const formData = new window.FormData()
  formData.append('type', 'aaa')
  formData.append('file', data, data.name || 'img' + new Date().getTime())
  const res = await reqService({
    method: 'POST',
    url: uploadFileUrl,
    data: formData,
  })
  return res
}
// 修改密码
export const updatePassword = async (data) => {
  const res = await reqService({
    method: 'POST',
    url: passwordUrl,
    params: data,
  })
  return res
}

// 登出
export const logout = async () => {
  const res = await reqService({
    method: 'get',
    url: logoutUrl,
  })
  return res
}

// 查询搜索记录
export const getSearchHistory = async (params) => {
  const res = await reqService({
    method: 'GET',
    url: searchHistoryUrl,
    params,
  })
  return res
}

// 保存搜索记录
export const postSearchHistory = async (data) => {
  const res = await reqService({
    method: 'POST',
    url: searchHistoryUrl,
    data,
  })
  return res
}
