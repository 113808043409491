import { http } from '../../utils/http'
import api from '../../api/login'
import qs from 'qs'
// 登录
export const login = (params) =>
  http.post(api.auth, qs.stringify(params), {
    headers: {
      Authorization: 'Basic HeSOVcmaqxiuOSu+3pvFalVwIkfBuRW8X5CHLRX6bcY=',
    },
  })

// 获取用户信息
export const getUserInfo = (params) => http.get(api.userInfo, params)
// 用户自己修改密码
export const changePassword = (params) => http.put(api.resetPassWord, params)
