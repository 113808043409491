<!-- 用户自定义平台列表 -->
<template>
  <div class="layout-menu-list flex">
    <draggable
      class="draggable-menu"
      element="ul"
      :disabled="disEditStatus"
      :animation="200"
      :value="customMenus"
      @input="handleChangeCustom"
    >
      <li
        v-for="item in customMenus"
        :key="item.name"
        :testid="`header_collect-${item.menuName}_1600918255387`"
        :class="[
          'system-item',
          { active: item.path === currentMenu.slice(1) },
          { outline: !disEditStatus }
        ]"
        @click="handleLinkTo(item)"
      >
        {{ item.menuName }}
      </li>
    </draggable>
    <i
      v-if="disEditStatus"
      class="el-icon-plus edit-status-btn"
      testid="header_addSys_1600918255386"
      @click="handleChangeEditStatus(false)"
    >
    </i>
    <transition name="slide-fade">
      <edit-application-dialog
        v-if="!disEditStatus"
        :custom-menus="customMenus"
        :all-plat="allPlat"
        :all-systems="allSystems"
        @close="handleChangeEditStatus(true)"
        @change="handleChangeCustom"
      ></edit-application-dialog>
    </transition>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import EditApplicationDialog from './editApplicationDialog.vue'

export default {
  props: {
    navbarMenus: {
      type: Array,
      default: () => []
    },
    currentMenu: {
      type: String,
      default: ''
    },
    // 默认锁定拖拽
    disEditStatus: {
      type: Boolean,
      default: false
    },
    // 所有平台
    allPlat: {
      type: Array,
      default: () => []
    },
    // 所有系统
    allSystems: {
      type: Array,
      default: () => []
    },
    // 用户自定义快捷访问菜单
    customMenus: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Draggable,
    EditApplicationDialog
  },
  computed: {},
  data() {
    return {
      editingSystems: [], // 编辑状态的子系统
      editedSystems: [], // 确认状态的子系统
      showEditApplication: false,
      selectedApplication: {}
    }
  },
  created() {
    this.getAuthSubmoduleSystem()
  },
  methods: {
    // 匹配出既有子模块应用（已开发有代码的系统），又有权限可访问的系统，并将旧新余与新路由合并为新的路由对象，以兼容新平台路由跳转与旧新余路由获取信息
    getAuthSubmoduleSystem() {
      const systems = JSON.parse(localStorage.getItem('classify')) || []
      // 将所有平台对应的系统取出来，合成新数组
      const allSystems = systems.reduce((pre, next) => {
        return next.children?.length ? pre.concat(next.children) : pre
      }, [])
      // 将所有系统与子模块匹配，过滤出既有子模块并且有权限的系统
      const submoduleSystem = []
      this.navbarMenus.forEach(submodule => {
        const system = allSystems.find(system => submodule.name === system.path)
        if (system) submoduleSystem.push(Object.assign({}, submodule, system))
      })

      this.editingSystems = submoduleSystem
    },
    handleLinkTo(systemMenu) {
      if (!this.disEditStatus) return
      this.$emit('select', systemMenu)
    },
    handleChangeEditStatus(boolean) {
      this.$emit('update:disEditStatus', boolean)
    },
    handleChangeCustom(menus) {
      this.$emit('update:customMenus', menus)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-menu-list {
  display: flex;
  align-items: center;
}

.draggable-menu {
  display: flex;
  padding: 0 17px;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

.system-item {
  cursor: pointer;
  padding: 0 8px;

  &:nth-child(n + 2) {
    margin-left: 9px;
  }

  &.outline {
    outline: 1px dashed #fff;
  }
}

.active {
  position: relative;
  &::after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -12px;
    left: 0;
    border-bottom: 2px solid #409eff;
  }
}

.edit-status-btn {
  cursor: pointer;
}
</style>
