export const baseUrl = '' // 开发环境下的请求地址
export const urlPre = baseUrl + '/api' // 请求前缀
export const esbPre = baseUrl + '/esb'
export const authUrlPre = urlPre + '/uaa' // 校验相关的请求路径的前缀
export const userUrlPre = urlPre + '/user' // 用户模块
export const rtmUpmsPre = urlPre + '/rtm-base' // 远传平台请求前缀（用于全局搜索）
export const comPre = urlPre + '/common' // 通用模块
export const waterRecoveryTopicUrl =
  urlPre + '/imis-dashboard/waterRecoveryTopic' // 水费回收率专题请求前缀
export const salesWaterTopicUrl = urlPre + '/imis-dashboard/salesWaterTopic' // 售水量专题请求前缀
export const layoutUrlPre = urlPre + '/imis-upms' // 布局模块
export const workflowviewPre = urlPre + '/imis-workflow/workflowview' // 工单总览请求前缀
export const comprehensiveUrlPre = urlPre + '/imis-dashboard/integrationManage' // 综管大屏请求前缀
export const realTimePre = esbPre + '/xinyu-dispatch/water/business'
export const workflowRealTimePre = urlPre + '/imis-workflow/integrationManage' // 大屏工单实时数据前缀
export const realTimeWorkflow = esbPre + '/xinyu-workflow/api/workflow/process'
export const usersUrl = urlPre + '/user/user' // 用户单点登录

export const getRecentOperationLogsUrl = urlPre + '/common/operation-logger' // 新平台端日志列表
