/* 固定电话 */
export function validateTelephone(str) {
  const reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/
  return reg.test(str)
}
/* 手机号码 */
export function validatePhoneNumber(str) {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  return reg.test(str)
}
/* 手机号码和固定电话 */
export function validatePhTelNumber(str) {
  const reg = /^[0-9\s-]{7,15}$/
  // const reg = /^(([0-9]{3,4}-)?[0-9]{7,8}$|(1[3456789]\d{9}))$/
  return reg.test(str)
}
export function validatePhTelNumber2(str) {
  const reg = /^[0-9\s-]{3,15}$/
  // const reg = /^(([0-9]{3,4}-)?[0-9]{7,8}$|(1[3456789]\d{9}))$/
  return reg.test(str)
}
/* 电子邮箱 */
export function validateEmail(str) {
  const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  return reg.test(str)
}
/* 邮编 */
export function validateZipCode(str) {
  const reg = /^[1-9][0-9]{5}$/
  return reg.test(str)
}
/* 身份证 */
export function validateIDCard(str) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return reg.test(str)
}
/* 银行卡号 15位或者16位或者19位 */
export function validateBank(str) {
  const reg = /^([1-9]{1})(\d{14}|\d{18}|\d{15})$/
  return reg.test(str)
}
/* 税号 15位或者18位或者20位 */
export function taxNumber(str) {
  const reg = /^([1-9]{1})([0-9A-Za-z]{14}|[0-9A-Za-z]{17}|[0-9A-Za-z]{19})$/
  return reg.test(str)
}
/* 纳税人识别码 */
export function validateTaxpayer(str) {
  const reg = /^([1-9]{1})(\d{14}|\d{18}|\d{15})$/
  return reg.test(str)
}
/* 小写字母 */
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}
/* 大写字母 */
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}
/* 大小写字母 */
export function validateAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
/*   不含中文*/
export function validateNoCh(str) {
  const reg = /[\u4e00-\u9fa5]/
  return !reg.test(str)
}
/*   数字或字母*/
export function validateNoAndStr(str) {
  const reg = /^[0-9a-zA-Z]*$/
  return reg.test(str)
}
/*   数字加字母*/
export function validateNoAndStr2(str) {
  const reg = /^(?![^a-zA-Z]+$)(?!\D+$)/
  return reg.test(str)
}
/*   输入框特殊字符*/
export function checkSpecificKey(str) {
  let specialKey = '[`^<>%]'
  for (let i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) !== -1) {
      return false
    }
  }
  return true
}
/*   检查经度是否合法*/
export function checkLon(lon) {
  let reg = /^-?((0|1?[0-8]?[0-9]?)(([.][0-9]{1,10})?)|180(([.][0]{1,10})?))$/
  return reg.test(lon)
}
/*   检查纬度是否合法*/
export function checkLat(lat) {
  let reg = /^-?((0|[1-8]?[0-9]?)(([.][0-9]{1,10})?)|90(([.][0]{1,10})?))$/
  return reg.test(lat)
}
/*   是否为纯数字*/
export function validateOnlyNum(str) {
  const reg = /(^[0-9][0-9]*(.[0-9]+)?)$/
  return reg.test(str)
}
/*   是否为字母开头*/
export function validateStrFirst(str) {
  const reg = /^[a-zA-Z]{1}/
  return reg.test(str)
}
/* 指定字符串为字母数字下划线和.*/
export function validateStrAppointerChars(str) {
  const reg = /^\w|\./gi
  return reg.test(str)
}
/* 微信账号校验 */
export function validateWechat(str) {
  const reg = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/
  return reg.test(str)
}
/* 指定字符串为字母数字下划线和-*/
export function validateStrAppointerChars2(str) {
  const reg = /^[0-9a-zA-Z-_]*$/
  return reg.test(str)
}