import { TEST_MODULE } from './types'
/**
 * vuex action
 */
export default {
  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  dispatchAction(context, payload) {
    context.commit(TEST_MODULE.ACTIONS, payload)
  },
}
