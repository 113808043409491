<!-- 旧新余这里header的弹窗都是一直重复写，样式也得个个写，抽离出来成一个组件，仅给header使用，看不懂以前弹窗视觉规范好像个个都有自己想法一样 -->
<template>
  <el-dialog
    class="custom-dialog"
    v-dialogDrag
    append-to-body
    :visible.sync="visible"
    :top="top"
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot slot="title" name="title"></slot>
    <slot slot="default"></slot>
    <slot v-if="isShowFooter" slot="footer" name="footer">
      <el-button size="small" @click="visible = false">{{ closeBtnText }}</el-button>
      <slot name="submit"></slot>
    </slot>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    top: {
      type: [Number, String],
      default: '15vh'
    },
    closeBtnText: {
      type: String,
      default: '取 消'
    }
  },
  computed: {
    isShowFooter() {
      // 正常来讲不会单独展示取消按钮，如果有也是叫关闭或其他文案，因此如果需要关闭功能又要叫取消，需要外部再次重新传入
      return this.$slots.footer || this.$slots.submit || this.$options.propsData.closeBtnText
    },
    defaultActive() {
      return this.$store.state.defaultActive
    }
  },
  data() {
    return {
      visible: false
    }
  },
  watch: {
    // 页面切换，关闭销毁该弹窗
    defaultActive() {
      this.visible = false
    }
  },
  mounted() {
    this.visible = true
  },
  methods: {
    toggle() {
      this.visible = !this.visible
    }
  }
}
</script>

<style lang="scss" scoped>
.align-center {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-dialog {
  /deep/ {
    .el-dialog {
      display: flex;
      max-height: 80%;
      margin-bottom: 0;
      flex-direction: column;
    }

    .el-dialog__header {
      padding: 20px 20px 10px;
      border-bottom: 1px solid #e5e5e5;
      cursor: move;
    }

    .el-dialog__title {
      font-size: 18px;
      line-height: 24px;
    }

    .el-dialog__body {
      flex: 1;
      overflow-y: auto;
      padding: 15px;
    }

    .el-dialog__footer {
      padding: 16px 0;
      text-align: center;
      border-top: 1px solid #e6e6e6;
    }
  }
}
</style>
