import { reqService } from 'comm/src/plugins/request'
import { userUrlPre, urlPre, authUrlPre } from 'comm/src/config'
const dictData = userUrlPre + '/dict/getRecordByCode' // 获取数据字典
const deptTree = userUrlPre + '/dept/tree-select' //获取组织树
const userTree = userUrlPre + '/user/listData' //获取用户树
const roleTree = userUrlPre + '/role/listData' //获取用户树
// 班组
const teamList = userUrlPre + '/team/list'
const teamAdd = userUrlPre + '/team/saveOrUpdate'
const teamDel = userUrlPre + '/team/'
const getPlatformInfoUrl = userUrlPre + '/tenant/getInfo' // 获取平台信息
const getTenantformInfoUrl = userUrlPre + '/tenant/info/current' // 获取租户信息
const updatePlatformInfoUrl = userUrlPre + '/tenant/editInfo' // 获取平台信息
const getPlatformDefault = userUrlPre + '/tenant/revertDefault' // 获取平台信息
// 数据权限
const permissionList = urlPre + '/imis-upms/permission/listPermissionData' // 拥有权限列表（综管）
const permissionSave = urlPre + '/imis-upms/permission/saveOrUpdateData' // 数据权限保存（综管）
const permissionFarDept = urlPre + '/rtm-base/permission/list' // 数据权限列表（远传）
const permissionFarSave = urlPre + '/rtm-base/permission/update' // 数据权限保存（远传）
const enginList = urlPre + '/anso-engineering/permission/list' // 数据权限列表（外业）
const enginSave = urlPre + '/anso-engineering/permission/save' // 数据权限保存（外业）
// 租户
const checkTenant = userUrlPre + '/tenant/checkTenant' // 校验租户

const tenantList = userUrlPre + '/tenant/list' // 租户列表
// 用户管理
const changeUserStatus = userUrlPre + '/user/updateEnabled' //修改用户状态
const teamListSel = userUrlPre + '/team/listTeam' //获取班组下拉列表
const checkUser = userUrlPre + '/user/checkUser' // 校验用户
const jobListSel = userUrlPre + '/job/listData' //获取岗位下拉列表
const telToNa = userUrlPre + '/user/getAccount' //根据手机号获取账号
const naToTel = userUrlPre + '/user/username' //根据账号获取手机号
// 登陆日志
const getLoginLogUrl = authUrlPre + '/login-info' // 获取登陆日志信息
const versionUrl = '/api/common/version-management/list' // 获取版本信息列表
const updateVersionUrl = '/api/common/version-management' // 更新及保存版本信息
const delVersionUrl = '/api/common/version-management/' // 版本删除
// 消息中心-短信模板
const smsList = urlPre + '/message-center/sms-template' // 消息模板-短信-列表
const smsSave = urlPre + '/message-center/sms-template' // 消息模板-短信-保存
const smsDel = urlPre + '/message-center/sms-template/' // 消息模板-短信-删除
// 消息中心-PUSH模板
const pushList = urlPre + '/message-center/pushtemplate' // 消息模板-PUSH-列表
const pushSave = urlPre + '/message-center/pushtemplate' // 消息模板-PUSH-保存
const pushDel = urlPre + '/message-center/pushtemplate/' // 消息模板-PUSH-删除
// 消息中心-站内信模板
const inMailList = urlPre + '/message-center/noticetemplate' // 消息模板-站内信-列表
const inMailSave = urlPre + '/message-center/noticetemplate' // 消息模板-站内信-保存
const inMailDel = urlPre + '/message-center/noticetemplate/' // 消息模板-站内信-删除
const inMailRecordUrl = urlPre + '/message-center/noticerecord' // 消息列表-站内信
const inMailRecordSaveUrl = urlPre + '/message-center/noticerecord' // 消息列表-站内信保存
const inMailRecordDetailUrl = urlPre + '/message-center/noticerecord/selfList' // 消息列表-站内信详情
const inMailRecordFilureUrl = urlPre + '/message-center/noticerecord/' // 消息列表-站内信失效
const inMailRecordReadUrl =
  urlPre + '/message-center/noticeuserstatus/getReadExtList' // 消息列表模板阅读次数
const inMailMessageListUrl = urlPre + '/message-center/noticerecord/selfList' // 站内信消息
const inMailMessageDelUrl =
  urlPre + '/message-center/noticeuserstatus/updateAllMsgByUserId' // 站内信消息记录删除
// 获取站内信类型
const inMailTypeListUrl = urlPre + '/message-center/noticetype/getAllNode'
// 获取通知公告类型
const getnoticeTypeUrl = urlPre + '/message-center/noticetype/getNode/'
// 短信记录
const getSmsRecordListUrl = urlPre + '/message-center/sms-record'
// 短信详情
const getSmsRecordDetailUrl = urlPre + '/message-center/sms-record/'
// 获取未读消息数据
const getNoReadMsgDataUrl =
  urlPre + '/message-center/noticeuserstatus/webClassifyList'
// '/message-center/noticerecord/personalMessageCount'
// 上传
const upload = urlPre + '/file/files-anon' //上传文件
const resetPasswordUrl = userUrlPre + '/tenant/resetPassword' // 重置租户密码
const serviceExpiresUrl = userUrlPre + '/tenant-project/checkServer' // 获取近一个月快过期的服务信息
const jobManagementListUrl = userUrlPre + '/job/list' // 获取岗位管理数据列表
const jobManagementUpdateUrl = userUrlPre + '/job/' // 获取岗位管理数据列表

const newQrCodeUrl = '/api/common/version-management/getVersions' // 获取最新版本path
const getInnerMailListUrl = urlPre + '/message-center/noticetype' // 站内信类型列表及保存
const chekInnerMailUrl = urlPre + '/message-center/noticetype/' // 站内信类型列表删除及查询
const importDetailUrl = userUrlPre + '/batch/' // 站内信类型列表
// 操作日志列表
const operationLogUrl = urlPre + '/common/operation-logger/list'
// 操作日志详情
const logDetailUrl = urlPre + '/common/operation-logger/'
// 获取租户列表
const tenantLsitUrl = userUrlPre + '/tenant/getList'

export const manageService = {
  // 获取数据字典
  getDictData: (params) => {
    return reqService({
      method: 'GET',
      url: dictData,
      params,
    })
  },
  // 获取组织树
  deptTree: () => {
    return reqService({
      method: 'GET',
      url: deptTree,
    })
  },
  // 获取用户树
  userTree: (params) => {
    return reqService({
      method: 'GET',
      url: userTree,
      params,
    })
  },
  // 获取角色树
  roleTree: (params) => {
    return reqService({
      method: 'GET',
      url: roleTree,
      params,
    })
  },
  // 获取组织树
  upload: (params) => {
    return reqService({
      method: 'POST',
      url: upload,
      data: params,
    })
  },
  // 获取班组列表数据
  getTeamList: (params) => {
    return reqService({
      method: 'GET',
      url: teamList,
      params,
    })
  },
  // 获取岗位列表数据
  getJobList: (params) => {
    return reqService({
      method: 'GET',
      url: jobListSel,
      params,
    })
  },
  // 班组列表删除
  teamDel: (params) => {
    return reqService({
      method: 'DELETE',
      url: teamDel + params,
    })
  },
  // 班组新增
  teamAdd: (params) => {
    return reqService({
      method: 'POST',
      url: teamAdd,
      data: params,
    })
  },
  // 班组详情
  teamDetail: (params) => {
    return reqService({
      method: 'GET',
      url: teamDel + params,
    })
  },

  getPlatFormInfo: () => {
    return reqService({
      method: 'POST',
      url: getPlatformInfoUrl,
    })
  },

  getTenantFormInfo: () => {
    return reqService({
      method: 'GET',
      url: getTenantformInfoUrl,
    })
  },

  updatePlatformInfo: (params) => {
    return reqService({
      method: 'POST',
      url: updatePlatformInfoUrl,
      data: params,
    })
  },

  getPlatformDefault: () => {
    return reqService({
      method: 'GET',
      url: getPlatformDefault,
    })
  },
  // 租户校验
  checkTenant: (params) => {
    return reqService({
      method: 'POST',
      url: checkTenant,
      data: params,
    })
  },
  // 用户户校验
  checkUser: (params) => {
    return reqService({
      method: 'POST',
      url: checkUser,
      data: params,
    })
  },
  // 修改用户状态
  changeUserStatus: (params) => {
    return reqService({
      method: 'GET',
      url: changeUserStatus,
      params,
    })
  },
  // 获取班组下拉列表
  getTeamListSel: (params) => {
    return reqService({
      method: 'POST',
      url: teamListSel,
      data: params,
    })
  },

  getLoginLogData: (params) => {
    return reqService({
      method: 'GET',
      url: getLoginLogUrl,
      params,
    })
  },

  exportExcel: (url, params, prefix, method) => {
    return reqService({
      method: method ? method : 'POST',
      url: (prefix ? urlPre : userUrlPre) + url,
      params,
      responseType: 'blob',
      headers: {
        Accept: 'application/x-www-form-urlencoded',
      },
    })
  },

  importExcel: (url, params) => {
    return reqService({
      method: 'POST',
      url: userUrlPre + url,
      data: params,
    })
  },

  getVersionData: (params) => {
    return reqService({
      method: 'GET',
      url: versionUrl,
      params,
    })
  },

  updateVersionData: (params) => {
    return reqService({
      method: 'POST',
      url: updateVersionUrl,
      data: params,
    })
  },

  delVersionData: (params) => {
    return reqService({
      method: 'DELETE',
      url: delVersionUrl + params.id,
    })
  },

  // 短信列表
  getSmsList: (params) => {
    return reqService({
      method: 'GET',
      url: smsList,
      params,
    })
  },
  // 保存短信
  smsSave: (params) => {
    return reqService({
      method: 'POST',
      url: smsSave,
      data: params,
    })
  },
  // 删除短信
  smsDel: (params) => {
    return reqService({
      method: 'DELETE',
      url: smsDel + params,
    })
  },
  // PUSH列表
  getPushList: (params) => {
    return reqService({
      method: 'GET',
      url: pushList,
      params,
    })
  },
  // 保存PUSH
  pushSave: (params) => {
    return reqService({
      method: 'POST',
      url: pushSave,
      data: params,
    })
  },
  // 删除PUSH
  pushDel: (params) => {
    return reqService({
      method: 'DELETE',
      url: pushDel + params,
    })
  },
  // 站内信列表
  getInMailList: (params) => {
    return reqService({
      method: 'GET',
      url: inMailList,
      params,
    })
  },
  // 保存站内信
  inMailSave: (params) => {
    return reqService({
      method: 'POST',
      url: inMailSave,
      data: params,
    })
  },
  // 删除站内信
  inMailDel: (params) => {
    return reqService({
      method: 'DELETE',
      url: inMailDel + params,
    })
  },
  // 站内信记录列表
  inMailRecord: (params) => {
    return reqService({
      method: 'GET',
      url: inMailRecordUrl,
      params,
    })
  },
  // 站内信记录保存
  inMailRecordSave: (params) => {
    return reqService({
      method: 'POST',
      url: inMailRecordSaveUrl,
      data: params,
    })
  },
  // 站内信记录详情
  inMailRecordDetail: (params) => {
    return reqService({
      method: 'GET',
      url: inMailRecordDetailUrl,
      params,
    })
  },
  // 站内信失效
  inMailRecordFilure: (params) => {
    return reqService({
      method: 'DELETE',
      url: inMailRecordFilureUrl + params,
    })
  },
  // 站内信获取单条记录
  getInMailRowData: (params) => {
    return reqService({
      method: 'GET',
      url: inMailRecordFilureUrl + params,
    })
  },
  // 站内信记录列表
  inMailMessageList: (params) => {
    return reqService({
      method: 'GET',
      url: inMailMessageListUrl,
      params,
    })
  },
  // 站内信记录删除
  inMailMessageDel: (params, method) => {
    return reqService({
      method,
      url: inMailMessageDelUrl,
      data: params,
    })
  },
  // 站内信获取浏览次数
  inMailRecordRead: (params) => {
    return reqService({
      method: 'GET',
      url: inMailRecordReadUrl,
      params,
    })
  },
  // 获取站内信类型
  getInMailTypeList: (params) => {
    return reqService({
      method: 'GET',
      url: inMailTypeListUrl,
      params,
    })
  },
  // 获取通知公告类型列表
  getNoticeTypeList: (params) => {
    return reqService({
      method: 'GET',
      url: getnoticeTypeUrl + params,
    })
  },
  // 获取短信记录
  getSmsRecordList: (params) => {
    return reqService({
      method: 'GET',
      url: getSmsRecordListUrl,
      params,
    })
  },
  // 获取短信详情
  getSmsRecordDetail: (params) => {
    return reqService({
      method: 'GET',
      url: getSmsRecordDetailUrl + params,
    })
  },
  // 获取未读消息数据
  getNoReadMsgData: (params) => {
    return reqService({
      method: 'GET',
      url: getNoReadMsgDataUrl,
      params,
    })
  },
  // 获取站内信类型列表
  getInnerMailTypeLsit: (params) => {
    return reqService({
      method: 'GET',
      url: getInnerMailListUrl,
      params,
    })
  },
  // 新增站内信类型数据
  saveInnerMailType: (params) => {
    return reqService({
      method: 'POST',
      url: getInnerMailListUrl,
      data: params,
    })
  },
  // 删除站内信类型数据
  delInnerMailType: (params) => {
    return reqService({
      method: 'DELETE',
      url: chekInnerMailUrl + params,
    })
  },
  // 查询站内信类型数据
  checkInnerMailType: (params) => {
    return reqService({
      method: 'GET',
      url: chekInnerMailUrl + params,
    })
  },
  // 租户列表
  getTenantList: (params) => {
    return reqService({
      method: 'GET',
      url: tenantList,
      params,
    })
  },
  resetPassword: (params) => {
    return reqService({
      method: 'PUT',
      url: resetPasswordUrl,
      data: params,
    })
  },

  getServiceExpiresData: (params) => {
    return reqService({
      method: 'GET',
      url: serviceExpiresUrl,
      params,
    })
  },

  getJobManagementData: (params) => {
    return reqService({
      method: 'GET',
      url: jobManagementListUrl,
      params,
    })
  },

  updateJobManagementData: (params, id, method) => {
    let url = id ? jobManagementUpdateUrl + `/${id}` : url
    return reqService({
      method,
      url: jobManagementUpdateUrl,
      data: params,
    })
  },

  getNewQrCodeUrl: (params) => {
    return reqService({
      method: 'GET',
      url: newQrCodeUrl,
      params,
    })
  },
  // 根据手机号获取账号
  getTelToNa: (params) => {
    return reqService({
      method: 'GET',
      url: telToNa,
      params,
    })
  },
  // 根据账号获取手机号
  getNaToTel: (params) => {
    return reqService({
      method: 'GET',
      url: naToTel,
      params,
    })
  },
  // 下载apk资源
  importDetail: (params) => {
    return reqService({
      method: 'GET',
      url: importDetailUrl + params,
    })
  },
  // 操作日志列表
  operationLog: (params) => {
    return reqService({
      method: 'GET',
      url: operationLogUrl,
      params,
    })
  },
  // 操作日志详情
  operationLogDetail: (params) => {
    return reqService({
      method: 'GET',
      url: logDetailUrl + params,
    })
  },
  // 获取租户列表数据
  getTenantInfo: (params) => {
    return reqService({
      method: 'GET',
      url: tenantLsitUrl,
      params,
    })
  },
  // 上传用户头像
  uploadImg: (params) => {
    return reqService({
      method: 'POST',
      url: '/api/user/user/headImgUrl',
      params,
    })
  },
}

// import { authUrlPre, DMAUrlPre, largeCaliberUrlPre, areaUrlPre } from '@/config'
// const { reqService } = require('anso-util').default
// const deptUrl = authUrlPre + '/dept' // 部门相关的url
// const batchDeptUrl = authUrlPre + '/dept/batch' // 部门批量操作的url
// const roleUrl = authUrlPre + '/role' // 角色相关的url
// const batchRoleUrl = authUrlPre + '/role/batch' // 角色批量操作的url
// const batchUserUrl = authUrlPre + '/user/batch'// 用户批量操作的url
// const userUrl = authUrlPre + '/user' // 用户相关的url
// const dictUrl = authUrlPre + '/dict' // 鉴权字典相关的url
// const permissionAllTreeUrl = authUrlPre + '/permission/tree' // 获取功能权限树的列表
// const permissionByIdUrl = authUrlPre + '/permission/getPermissListByRole' // 根据角色Id获取角色权限
// const savePermissionUrl = authUrlPre + '/permission/saveRolePermissionRelation' // 保存角色权限
// const loginLogUrl = authUrlPre + '/loginLog' // 保存角色权限
// const menuListTreeUrl = authUrlPre + '/permission/listTree' // 菜单权限树
// const clientTypeTreeUrl = authUrlPre + '/permission/clientTypeTree' // 菜单权限树（新web+App）
// const savePermissionAndResourceUrl = authUrlPre + '/permission/savePermissionAndResource' // 添加菜单
// const editPermissionAndResourceUrl = authUrlPre + '/permission/editPermissionAndResource' // 编辑菜单
// const menuDeleteUrl = authUrlPre + '/permission/menuDelete/' // 删除菜单

// // 数据权限
// const getDMATreeUrl = DMAUrlPre + '/public/listDataPermissionTree' // DMA树
// const saveDataLimitUrl = authUrlPre + '/dataPermission' // 根据角色id保存权限
// const getDataLimitUrl = authUrlPre + '/dataPermission' // 根据角色id获取数据权限

// const getLargeCalierTreeUrl = largeCaliberUrlPre + '/informationManager/user/userTree' // 远传表树
// const getLargeCalierFMTypeTreeUrl = largeCaliberUrlPre + '/informationManager/user/table_type_org' // 远传表树
// const getLargeCalierFactoryTreeUrl = largeCaliberUrlPre + '/informationManager/baseSet/factory/validData' // 远传表树

// const getPartCalcTreeUrl = areaUrlPre + '/base-info/area-org/tree' // 分区计量树

// const workFlowPermissUrl = authUrlPre + '/dataPermission/HFlow' // 获取角色下的工单流程权限

// // 查询鉴权字典
// export const queryDict = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: dictUrl,
//     params: params
//   })
//   return res
// }
// // 查询部门
// export const queryDept = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: deptUrl,
//     params: params
//   })
//   return res
// }
// // 修改部门
// export const modifyDept = async (params) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: deptUrl + '/' + params.id,
//     data: params
//   })
//   return res
// }
// // 添加部门
// export const addDept = async (params) => {
//   const res = await reqService({
//     method: 'POST',
//     url: deptUrl,
//     data: params
//   })
//   return res
// }
// // 实时校验部门信息
// export const realTimeValidateDeptInfo = async (params) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: deptUrl + '/validateFields',
//     data: params
//   })
//   return res
// }
// // 删除部门
// export const delDept = async (params) => {
//   const res = await reqService({
//     method: 'DELETE',
//     url: deptUrl + '/' + params.id,
//     data: params
//   })
//   return res
// }
// // 批量删除部门
// export const batchDelDept = async (params) => {
//   const res = await reqService({
//     method: 'DELETE',
//     url: batchDeptUrl,
//     data: params
//   })
//   return res
// }

// // 查询角色
// export const queryRole = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: roleUrl,
//     params: params
//   })
//   return res
// }
// // 修改角色
// export const modifyRole = async (params) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: roleUrl + '/' + params.id,
//     data: params
//   })
//   return res
// }
// // 添加角色
// export const addRole = async (params) => {
//   const res = await reqService({
//     method: 'POST',
//     url: roleUrl,
//     data: params
//   })
//   return res
// }
// // 实时校验角色信息
// export const realTimeValidateRoleInfo = async (params) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: roleUrl + '/validateFields',
//     data: params
//   })
//   return res
// }
// // 删除角色
// export const delRole = async (params) => {
//   const res = await reqService({
//     method: 'DELETE',
//     url: roleUrl + '/' + params.id,
//     data: params
//   })
//   return res
// }
// // 删除角色
// export const batchDelRole = async (params) => {
//   const res = await reqService({
//     method: 'DELETE',
//     url: batchRoleUrl,
//     data: params
//   })
//   return res
// }

// // 查询用户
// export const queryUser = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: userUrl,
//     params: params
//   })
//   return res
// }
// // 修改用户
// export const modifyUser = async (params) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: userUrl + '/' + params.id,
//     data: params
//   })
//   return res
// }
// // 添加用户
// export const addUser = async (params) => {
//   const res = await reqService({
//     method: 'POST',
//     url: userUrl,
//     data: params
//   })
//   return res
// }
// // 实时校验用户信息
// export const realTimeValidateUserInfo = async (params) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: userUrl + '/validateFields',
//     data: params
//   })
//   return res
// }
// // 删除用户
// export const delUser = async (params) => {
//   const res = await reqService({
//     method: 'DELETE',
//     url: userUrl,
//     data: params
//   })
//   return res
// }
// // 批量删除用户
// export const batchDelUser = async (params) => {
//   const res = await reqService({
//     method: 'DELETE',
//     url: batchUserUrl,
//     data: params
//   })
//   return res
// }
// // 获取所有的权限树
// export const getAllPermission = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: permissionAllTreeUrl,
//     params: params
//   })
//   return res
// }
// // 根据角色Id获取权限树
// export const getLimitByRoleID = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: permissionByIdUrl,
//     params: params
//   })
//   return res
// }
// // 保存角色权限
// export const savePermission = async (params) => {
//   const res = await reqService({
//     method: 'POST',
//     url: savePermissionUrl,
//     data: params
//   })
//   return res
// }
// // 根据角色Id获取工单流程配置
// export const getWorkFlowPermission = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: workFlowPermissUrl,
//     params: params
//   })
//   return res
// }
// // 保存工单流程配置
// export const saveWorkFlowPermission = async (params) => {
//   const res = await reqService({
//     method: 'POST',
//     url: workFlowPermissUrl,
//     data: params
//   })
//   return res
// }
// // 数据权限
// // 获取DMA树
// export const getDMATree = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: getDMATreeUrl,
//     params: params
//   })
//   return res
// }
// // 获取分区计量树
// export const getPartCalcTree = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: getPartCalcTreeUrl,
//     params: params
//   })
//   return res
// }
// // 获取菜单权限树
// export const menuListTree = async () => {
//   const res = await reqService({
//     method: 'GET',
//     url: menuListTreeUrl
//   })
//   return res
// }
// // 获取菜单权限树
// export const clientTypeTree = async () => {
//   const res = await reqService({
//     method: 'GET',
//     url: clientTypeTreeUrl
//   })
//   return res
// }
// // 保存数据权限
// export const saveDataLimit = async (params) => {
//   const res = await reqService({
//     method: 'POST',
//     url: saveDataLimitUrl,
//     data: params
//   })
//   return res
// }
// // 根据角色获取DMA数据权限
// export const getDataLimitByRole = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: getDataLimitUrl,
//     params: params
//   })
//   return res
// }
// // 查询登录日志
// export const loginLog = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: loginLogUrl,
//     params
//   })
//   return res
// }
// // 获取远传表树
// export const getLargeCalierTree = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: getLargeCalierTreeUrl,
//     params: params
//   })
//   return res
// }
// // 获取远传表类型树
// export const getLargeCalierFMTypeTree = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: getLargeCalierFMTypeTreeUrl,
//     params: params
//   })
//   return res
// }
// // 获取远传表类型树
// export const getLargeCalierFactoryTree = async (params) => {
//   const res = await reqService({
//     method: 'GET',
//     url: getLargeCalierFactoryTreeUrl,
//     params: params
//   })
//   return res
// }
// // 添加菜单
// export const savePermissionAndResource = async (params) => {
//   const res = await reqService({
//     method: 'POST',
//     url: savePermissionAndResourceUrl,
//     data: params
//   })
//   return res
// }
// // 编辑菜单
// export const editPermissionAndResource = async (params) => {
//   const res = await reqService({
//     method: 'PUT',
//     url: editPermissionAndResourceUrl,
//     data: params
//   })
//   return res
// }
// // 删除菜单
// export const menuDelete = async (params) => {
//   const res = await reqService({
//     method: 'DELETE',
//     url: menuDeleteUrl + params.id,
//     data: params
//   })
//   return res
// }
