/**
 *
 * @param {*} el  挂载对象
 * @param {*} App  渲染对象
 * @param {*} options  Vue实例数据总线
 */
import { checkType } from './utils'
export default function renderApp(Vue, App, options) {
  // if (options.hasOwnProperty('plugins')) {
  //   if (Array.isArray(options.plugins)) {
  //     options.plugins.forEach((p) => {
  //       p.components &&
  //         p.components.forEach((c) => {
  //           Vue.use(c)
  //         })

  //       p.install && p.install(Vue)
  //     })
  //   }
  // }
  // if (options.hasOwnProperty('init')) {
  //   if (typeof options.init === 'function') {
  //     options.init(Vue)
  //   }
  // }
  // // Object.assign(vmOptions, { render: (h) => h(App) })
  // const ctx = new Vue({
  //   el: options.el,
  //   ...options._install,
  //   render: (h) => h(App),
  // })
  // return ctx

  const instance = new Vue(
    Object.assign(usePlugins(Vue, options), { render: (h) => h(App) })
  )
  // window.__SetSubAppLoading__ = setSubAppLoading(instance)

  return instance
}

/**
 * todo： 实现如图例所示传参，待处理。
 *  renderApp(Vue, App, {
    router,
    store,
    plugins: [ele],
    fromWeb: 'main',
    el: '#app',
    init: vm => {
        // vm.use of vm.prototype.xxx =xxx
    }
 */

/**
 *
 * @param {*} vm
 * @param {Object} options
 * @returns {Object} vmOptions
 */
function usePlugins(vm, options) {
  const vmOptions = {}
  for (const [k, v] of Object.entries(options)) {
    switch (checkType(v)) {
      case 'function':
        v(vm)
        break
      case 'array':
        v.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(item, 'components')) {
            item.components.forEach((c) => vm.use(c))
            item.install && item.install(vm)
          } else if (Object.prototype.hasOwnProperty.call(item, 'install')) {
            item.install(vm)
          } else {
            vm.use(item)
          }
        })
        break
      default:
        Object.assign(vmOptions, { [k]: v })
        break
    }
  }
  return vmOptions
}
